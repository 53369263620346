import React, { useCallback, useEffect, useMemo, useState } from 'react';

import * as Sentry from '@sentry/nextjs';
import useSWR from 'swr';

import useApi from '@components/api/useApi';

import UserContext from './UserContext';

const UserContextProvider = ({ children }) => {
    const [isMature, setIsMature] = useState(null);
    const api = useApi();
    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(true);
    const { data: rawLimits, isValidating: limitsLoading } = useSWR(['/users/limits', { useCache: true }]);
    const limitOverrides = user?.limitOverrides;
    const userPermissions = useMemo(() => user?.userPermissions || [], [user?.userPermissions]);
    const isAdmin = userPermissions.some(({ permission }) => permission === 'admin');

    const reloadUser = useCallback(async params => {
        const { silent } = params || {};

        if (!silent) {
            setUserLoading(true);
        }

        const user = await api.fetcher('/my');
        setUser(user);
        setUserLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        reloadUser();
    }, [reloadUser]);

    useEffect(() => {
        if (user) {
            setIsMature(user.isMature);
            Sentry.setUser({
                id: user.id,
            });
        } else {
            setIsMature(null);
            Sentry.setUser(null);
        }
    }, [user]);

    const changeMature = useCallback(value => setIsMature(value), []);

    const hasPermissions = useCallback(
        (desiredPermissions, entityId) => {
            const entityPermissions = entityId
                ? userPermissions.filter(userPermission => userPermission.entityId === entityId)
                : userPermissions;
            return isAdmin || entityPermissions.some(({ permission }) => desiredPermissions.includes(permission));
        },
        [isAdmin, userPermissions]
    );

    const limits = useMemo(() => {
        return (rawLimits || []).reduce((acc, { key, defaultValue }) => {
            return { ...acc, [key]: limitOverrides?.[key] || defaultValue };
        }, {});
    }, [limitOverrides, rawLimits]);

    const userPodcasters = useMemo(
        () => (userPermissions || []).filter(({ permission }) => permission === 'podcaster'),
        [userPermissions]
    );

    return (
        <UserContext.Provider
            value={{
                user,
                loading: userLoading || limitsLoading,
                limits,
                userPodcasters,
                rawLimits,
                reloadUser,
                isAdmin,
                isMature,
                changeMature,
                hasPermissions,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
