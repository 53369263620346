import PropTypes from 'prop-types';

const tablePropTypes = {
    rows: PropTypes.array,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
            id: PropTypes.string.isRequired,
            render: PropTypes.func.isRequired,
            sx: PropTypes.object,
        })
    ),
    rowKey: PropTypes.string.isRequired,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    loading: PropTypes.bool,
    emptyText: PropTypes.string,
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
    totalCount: PropTypes.number,
    onLoadMoreClick: PropTypes.func,
    variant: PropTypes.oneOf(['paginated', 'infinite']),
    emptyAction: PropTypes.element,
    hideTableFooter: PropTypes.bool,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    orderable: PropTypes.bool,
    onReorder: PropTypes.func,
    onRowClick: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onSelect: PropTypes.func,
    editable: PropTypes.bool,
    selectable: PropTypes.bool,
    selectedKeys: PropTypes.array,
    onSelectAll: PropTypes.func,
    maxHeight: PropTypes.number,
    size: PropTypes.oneOf(['small', 'medium']),
    hideTableHeader: PropTypes.bool,
    hover: PropTypes.bool,
};

export default tablePropTypes;
