import React from 'react';

import PropTypes from 'prop-types';

import Box from '@ui/Box';
import Dialog from '@ui/Dialog';
import DialogActions from '@ui/DialogActions';
import DialogContent from '@ui/DialogContent';
import DialogTitle from '@ui/DialogTitle';
import Button from '@ui/inputs/Button';

import useCms from '@components/Cms/useCms';
import AuthButtons from '@components/page/AuthButtons';
import useUser from '@components/User/useUser';

const LockedContentPopup = ({ isOpen, onClose }) => {
    const cms = useCms();
    const { user } = useUser();

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{cms[4135]}</DialogTitle>
            <DialogContent>
                <Box>{cms[4137]}</Box>
            </DialogContent>
            <DialogActions>
                {user ? (
                    <Button onClick={handleClose} variant="outlined">
                        {cms[4136]}
                    </Button>
                ) : (
                    <AuthButtons text onRegisterClick={handleClose} onLoginClick={handleClose} />
                )}
            </DialogActions>
        </Dialog>
    );
};

LockedContentPopup.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

export default LockedContentPopup;
