import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useRouter } from 'next/router';
import { toastApiError, toastSuccess } from 'utils/toast';

import { getUrlParam } from './browser';
import { filterObect } from './collection';
import { getId } from './common';

export const useAsyncHandler = (
    {
        handler,
        onSuccess,
        onFail,
        successMessage,
        defaultErrorMessage,
        defaultLoading,
        stopLoadingOnSuccess,
        muteNotifications,
    },
    dependencies
) => {
    const [loading, setLoading] = useState(defaultLoading || false);

    const handleApiCall = useCallback(async (...params) => {
        try {
            setLoading(true);
            const result = await handler(...params);
            if (stopLoadingOnSuccess) {
                setLoading(false);
            }

            if (!muteNotifications) {
                successMessage && toastSuccess(successMessage);
            }

            onSuccess && onSuccess(result);
        } catch (error) {
            setLoading(false);
            if (!muteNotifications) {
                toastApiError(error, defaultErrorMessage);
            }

            onFail && onFail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies || []);

    return [handleApiCall, loading];
};

export const useIsDesktop = () => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return isDesktop;
};

export const useFilters = ({ filterKeys, hideInUrl }) => {
    const router = useRouter();
    const defaultFilters = useMemo(
        () =>
            filterKeys.reduce(
                (acc, filterKey) => ({ ...acc, [filterKey]: hideInUrl ? '' : getUrlParam(filterKey) || '' }),
                {}
            ),
        [filterKeys, hideInUrl]
    );
    const [filters, setFilters] = useState(defaultFilters);

    const resetFilters = useCallback(() => {
        setFilters(filterKeys.reduce((acc, filterKey) => ({ ...acc, [filterKey]: '' }), {}));
    }, [filterKeys]);

    useEffect(() => {
        if (hideInUrl) {
            return;
        }

        router.push({
            pathname: router.pathname,
            query: filterObect({ ...router.query, ...filters }, value => !!String(value)),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return [filters, setFilters, resetFilters];
};

export const useId = (prefix = 'component') => {
    const [id] = useState(getId(prefix));
    return id;
};

export const useTabs = ({ initialTabId }) => {
    const router = useRouter();
    const tab = router.query.tab;
    const [tabId, setTabId] = useState(tab || initialTabId);

    useEffect(() => {
        if (tab) {
            setTabId(tab);
        } else {
            router.replace({
                pathname: router.pathname,
                query: { ...router.query, tab: initialTabId },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab, initialTabId]);

    const handleChangeTabId = useCallback(tab => {
        router.push({
            pathname: router.pathname,
            query: { ...router.query, tab },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [tabId, handleChangeTabId];
};

export const useAsyncReference = (value, isProp = false) => {
    const ref = useRef(value);
    const [, forceRender] = useState(false);

    function updateState(newState) {
        if (!Object.is(ref.current, newState)) {
            ref.current = newState;
            forceRender(s => !s);
        }
    }

    if (isProp) {
        ref.current = value;
        return ref;
    }

    return [ref, updateState];
};
