export const filterObect = (object, filter) => {
    return Object.keys(object).reduce((acc, key) => {
        const value = object[key];
        if (filter(value, key)) {
            return { ...acc, [key]: value };
        }

        return acc;
    }, {});
};

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
