import React, { useState } from 'react';

import AccountCircle from '@mui/icons-material/AccountCircle';
import { clearSession } from '@utils/session';
import { getLinks } from 'cache';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useIsDesktop } from 'utils/hooks';

import Box from '@ui/Box';
import IconButton from '@ui/IconButton';
import Link from '@ui/link/Link';
import Menu from '@ui/Menu';
import MenuItem from '@ui/MenuItem';

import useCms from '@components/Cms/useCms';
import useUser from '@components/User/useUser';

import AuthButtons from '../AuthButtons';

const UserMenu = ({ onClose, hideProfileMenu = false }) => {
    const { isAdmin, user, reloadUser, loading } = useUser();
    const isDesktop = useIsDesktop();
    const cms = useCms();
    const [anchorEl, setAnchorEl] = useState(null);
    const router = useRouter();

    if (!isDesktop && !user) {
        return null;
    }

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        onClose?.();
    };

    const handleLogout = async () => {
        handleClose();
        clearSession();
        await reloadUser();
        router.push(getLinks('login'));
    };

    if (loading) {
        return null;
    }

    if (!user) {
        return <AuthButtons onRegisterClick={onClose} onLoginClick={onClose} />;
    }

    return (
        <Box>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {!hideProfileMenu && (
                    <MenuItem
                        component={Link}
                        onClick={handleClose}
                        href={getLinks('profile')}
                        color="inherit"
                        underline="none"
                    >
                        {cms[4]}
                    </MenuItem>
                )}
                {isAdmin && (
                    <MenuItem
                        component={Link}
                        onClick={handleClose}
                        href={getLinks('admin')}
                        color="inherit"
                        underline="none"
                    >
                        Admin
                    </MenuItem>
                )}
                <MenuItem
                    component={Link}
                    href={getLinks('logout')}
                    onClick={handleLogout}
                    color="inherit"
                    underline="none"
                >
                    {cms[5]}
                </MenuItem>
            </Menu>
        </Box>
    );
};

UserMenu.propTypes = {
    onClose: PropTypes.func,
    hideProfileMenu: PropTypes.bool,
};

export default UserMenu;
