import PropTypes from 'prop-types';

const metaPropTypes = {
    meta: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        image: PropTypes.string,
        imageWidth: PropTypes.string,
        imageHeight: PropTypes.string,
        ogType: PropTypes.oneOf([
            'music.song',
            'music.album',
            'music.playlist',
            'music.radio_station',
            'video.movie',
            'video.episode',
            'video.tv_show',
            'video.other',
            'article',
            'book',
            'profile',
            'website',
        ]),
    }),
    schemaJson: PropTypes.object,
};

export default metaPropTypes;
