import React from 'react';

import { Toolbar as MuiToolbar } from '@mui/material';

const Toolbar = props => {
    const { children, ...rest } = props;
    return <MuiToolbar {...rest}>{children}</MuiToolbar>;
};

export default Toolbar;
