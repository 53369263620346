import { getFromLocalStorage } from '@utils/storage';

export const baseSettings = {
    licenseKey: process.env.NEXT_PUBLIC_RADIANT_PLAYER_KEY,
    adLocale: 'hu',
    width: 1920,
    ajaxWithCredentials: true,
    ads: true,
    audioOnly: true,
    srcChangeAutoplay: true,
    requestAutoplayCapabilitiesData: true,
    initialVolume: 0.5,
    googleCast: false,
    airplay: false,
    hideControls: true,
};

export const initialContext = () => ({
    isAdSkippable: false,
    adSkipTimeOffset: 0,
    playingAtSeconds: 0,
    isAutoPlayDisabled: false,
    currentTrack: null,
    offsetStartPosition: 0,
    isAdPlaying: false,
    isPlaying: false,
    playListId: null,
    playbackRate: 1,
    volume: 0.5,
    muted: false,
    loading: false,
    autoPlayNext: !getFromLocalStorage('disableAutoPlayNext')?.value,
});

const supportedExtensions = ['ogg', 'm4a']; // radiant player in audio only mode only supports these

export const getSrc = currentTrack => {
    const { assets, streamingUrls, type } = currentTrack;
    const urls = streamingUrls?.length ? streamingUrls : assets?.[0]?.streamingUrls;

    if (type === 'radio') {
        const url = urls[0];
        const extension = url.split('.').pop();

        const key = supportedExtensions.includes(extension) ? extension : 'mp3';

        return {
            [key]: url,
        };
    }

    return {
        mp3: urls?.[0],
    };

    // if (rssFeed) {
    // return {
    //     mp3: urls?.[0],
    // };
    // }

    // return {
    //     hls: urls?.[0],
    //     dash: urls?.[1],
    // };
};

export const getCursor = mediaData => {
    return mediaData?.cursor;
};

export const getAdTagUrl = track => {
    if (process.env.NEXT_PUBLIC_TEST_AD_TAG) {
        return process.env.NEXT_PUBLIC_TEST_AD_TAG;
    }

    const { generatedAdUrl, properties } = track || {};

    if (properties?.isAdDisabled === 'true') {
        console.warn('Ads are disabled for this media');
        return null;
    }

    return generatedAdUrl || null;
};
