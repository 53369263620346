import React, { useState } from 'react';

import { FlagRounded } from '@mui/icons-material';

import IconButton from '@ui/IconButton';
import Tooltip from '@ui/Tooltip';

import useCms from '@components/Cms/useCms';
import ReportModal from '@components/Media/ReportModal';
import useRegisterDialog from '@components/RegisterDialog/useRegisterDialog';
import useUser from '@components/User/useUser';

const ReportButton = ({ rmpRef, currentTrackRef, mediaData, pause }) => {
    const [reportDetails, setReportDetails] = useState(null);
    const { openRegisterDialog } = useRegisterDialog();
    const { user } = useUser();
    const cms = useCms();

    const openReportModal = () => {
        if (!user) {
            openRegisterDialog();
            return;
        }

        if (pause) {
            pause();
        }

        setReportDetails({
            mediaData: currentTrackRef ? currentTrackRef.current : mediaData,
            playingMediaTime: rmpRef ? Math.round((rmpRef?.current?.getCurrentTime() || 0) / 1000) : 0,
        });
    };

    return (
        <>
            <Tooltip title={cms[704]}>
                <IconButton aria-label={cms[704]} onClick={openReportModal}>
                    <FlagRounded color="secondary" />
                </IconButton>
            </Tooltip>
            {reportDetails && <ReportModal onClose={() => setReportDetails(null)} {...reportDetails} />}
        </>
    );
};

export default ReportButton;
