import React from 'react';

import List from '@ui/List';
import ListSubheader from '@ui/ListSubheader';

const SidebarList = ({ label, children }) => {
    return (
        <List
            component="nav"
            sx={{pb: 2}}
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    {label}
                </ListSubheader>
            }
        >
            {children}
        </List>
    );
};

export default SidebarList;
