import React from 'react';

import PropTypes from 'prop-types';
import { FormProvider } from 'react-hook-form';

const FormFromHook = ({ children, form, onSubmit, ...rest }) => {
    const { handleSubmit } = form;

    return (
        <FormProvider {...form}>
            <form onSubmit={onSubmit && handleSubmit(onSubmit)} noValidate {...rest}>
                {children}
            </form>
        </FormProvider>
    );
};

FormFromHook.propTypes = {
    form: PropTypes.object,
    onSubmit: PropTypes.func,
};

export default FormFromHook;
