import React from 'react';

import IconButton from '@ui/IconButton';
import { NextLinkComposed } from '@ui/link/Link';

const IconButtonLink = props => {
    const { children, ...rest } = props;
    return (
        <IconButton component={NextLinkComposed} {...rest}>
            {children}
        </IconButton>
    );
};

export default IconButtonLink;
