export const getUrlParam = param => {
    if (!global.window) {
        return {};
    }

    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
};

export const getUrlParams = () => {
    if (!global.window) {
        return {};
    }

    let obj = {};
    new URLSearchParams(window.location.search).forEach((v, k) => {
        obj = { ...obj, [k]: v };
    });

    return obj;
};

export const objectToParamString = (obj, ignoreNull) => {
    const cleanObj = Object.keys(obj).reduce((acc, key) => {
        if (ignoreNull && !String(obj[key])) {
            return acc;
        }

        return { ...acc, [key]: obj[key] };
    }, {});
    const paramString = Object.keys(cleanObj)
        .map(key => {
            if (Array.isArray(obj[key])) {
                return obj[key].map(param => `${key}=${encodeURIComponent(param)}`);
            }

            return `${key}=${encodeURIComponent(obj[key])}`;
        })
        .flat()
        .join('&');

    return paramString;
};

export const combinePath = (baseUrl, endpoint) => {
    if (!endpoint) {
        return baseUrl;
    }

    return `${baseUrl}${baseUrl.endsWith('/') ? '' : '/'}${
        endpoint.startsWith('/') ? endpoint.substring(1) : endpoint
    }`;
};
