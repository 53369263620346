import React from 'react';

import Button from '@ui/inputs/Button';
import { NextLinkComposed } from '@ui/link/Link';

const ButtonLink = props => {
    const { children, ...rest } = props;
    return (
        <Button component={NextLinkComposed} {...rest}>
            {children}
        </Button>
    );
};

export default ButtonLink;
