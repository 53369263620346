/* eslint-disable max-lines */
import React, { useCallback, useMemo } from 'react';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CategoryIcon from '@mui/icons-material/CategoryRounded';
import FlagIcon from '@mui/icons-material/Flag';
import GroupIcon from '@mui/icons-material/GroupRounded';
import HomeIcon from '@mui/icons-material/HomeRounded';
import IntegrationInstructionsRoundedIcon from '@mui/icons-material/IntegrationInstructionsRounded';
import MicIcon from '@mui/icons-material/Mic';
import PodcastsIcon from '@mui/icons-material/PodcastsRounded';
import PolicyRoundedIcon from '@mui/icons-material/PolicyRounded';
import RadioIcon from '@mui/icons-material/RadioRounded';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOverRounded';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import { getLinks } from 'cache';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import useCms from '@components/Cms/useCms';

import SidebarList from './SidebarList';
import SidebarListItem from './SidebarListItem';

const AdminSidebar = ({ onClose }) => {
    const cms = useCms();
    const router = useRouter();
    const location = router.asPath;

    const getIsActiveBasic = useCallback(path => path === location, [location]);
    const getIsActiveNested = useCallback(path => location.includes(path), [location]);

    const links = useMemo(
        () =>
            [
                {
                    label: cms[16],
                    listItems: [
                        {
                            path: getLinks('admin'),
                            label: cms[137],
                            icon: <HomeIcon />,
                            getIsActive: getIsActiveBasic,
                        },
                        {
                            path: getLinks('adminPodcasts'),
                            label: cms[138],
                            icon: <PodcastsIcon />,
                            getIsActive: getIsActiveNested,
                        },
                        {
                            path: getLinks('adminEpisodes'),
                            label: cms[650],
                            icon: <MicIcon />,
                            getIsActive: getIsActiveNested,
                        },
                        {
                            path: getLinks('adminRadios'),
                            label: cms[459],
                            icon: <RadioIcon />,
                            getIsActive: getIsActiveNested,
                        },
                        {
                            path: getLinks('adminHosts'),
                            label: cms[411],
                            icon: <RecordVoiceOverIcon />,
                            getIsActive: getIsActiveNested,
                        },
                        {
                            path: getLinks('adminCategories'),
                            label: cms[423],
                            icon: <CategoryIcon />,
                            getIsActive: getIsActiveNested,
                        },
                        {
                            path: getLinks('adminBanners'),
                            label: cms[529],
                            icon: <ViewCarouselIcon />,
                            getIsActive: getIsActiveNested,
                        },
                        {
                            path: getLinks('adminPromoBoxes'),
                            label: cms[4500],
                            icon: <IntegrationInstructionsRoundedIcon />,
                            getIsActive: getIsActiveNested,
                        },
                        {
                            path: getLinks('adminUsers'),
                            label: cms[139],
                            icon: <GroupIcon />,
                            getIsActive: getIsActiveNested,
                        },
                        {
                            path: getLinks('adminReview'),
                            label: cms[1201],
                            icon: <PolicyRoundedIcon />,
                            getIsActive: getIsActiveNested,
                        },
                        {
                            path: getLinks('adminReports'),
                            label: cms[834],
                            icon: <FlagIcon />,
                            getIsActive: getIsActiveNested,
                        },
                        {
                            path: getLinks('adminPodcasters'),
                            label: cms[3027],
                            icon: <GroupIcon />,
                            getIsActive: getIsActiveNested,
                        },
                    ],
                },
                {
                    label: cms[477],
                    listItems: [
                        {
                            path: getLinks('home'),
                            getIsActive: getIsActiveBasic,
                            label: cms[469],
                            icon: <ArrowBackRoundedIcon />,
                        },
                    ],
                },
            ].map(({ label, listItems }) => (
                <SidebarList label={label} key={label}>
                    {listItems.map(({ path, label, icon, getIsActive }) => (
                        <SidebarListItem
                            key={label}
                            active={getIsActive(path)}
                            pathname={path}
                            label={label}
                            icon={icon}
                            onClose={onClose}
                        />
                    ))}
                </SidebarList>
            )),
        [cms, onClose, getIsActiveBasic, getIsActiveNested]
    );

    return links;
};

AdminSidebar.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default AdminSidebar;
