import React, { useEffect, useState } from 'react';

import { getLinks } from 'cache';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { mediaTypes, statPermissions } from 'utils/media';
import { redirectToLogin } from 'utils/token';

import PageLoader from '@ui/PageLoader';

import useCms from '@components/Cms/useCms';
import useUser from '@components/User/useUser';

import Layout from './Layout';
import { layoutTypes } from './layoutTypes';
import Meta from './Meta/Meta';
import SimpleLayout from './SimpleLayout';

const Page = ({ children, isProtected, layoutType, isAdminPage, pageProps, getMeta, getSchemaJson }) => {
    const userContext = useUser();
    const [isAllowed, setIsAllowed] = useState(!isProtected);
    const cms = useCms();
    const router = useRouter();
    const isLoading = (userContext.loading && isProtected) || cms.loading;
    const mediaId = pageProps?.media?.id;
    const mediaType = pageProps?.media?.type;

    useEffect(() => {
        const user = userContext.user;

        if (userContext.loading) {
            return;
        }

        if (router.pathname !== getLinks('email') && user && !user.isEmailVerified) {
            router.replace(getLinks('email'));
            return;
        }

        if (router.pathname !== getLinks('complete-profile') && user && !user.isProfileDataCompleted) {
            router.replace(getLinks('complete-profile'));
            return;
        }

        if (isProtected && !user) {
            redirectToLogin(router);
            return;
        }

        if (isAdminPage && !userContext.isAdmin) {
            router.replace('/');
            return;
        }

        if (router.pathname === getLinks('email') && user && user.isEmailVerified) {
            router.replace(getLinks('complete-profile'));
            return;
        }

        if (
            router.pathname.includes(getLinks('stats')) &&
            (mediaType !== mediaTypes.radio || !userContext.hasPermissions(statPermissions, mediaId))
        ) {
            router.replace(getLinks('home'));
            return;
        }

        setIsAllowed(!isProtected || (!isAdminPage && user) || (isAdminPage && userContext.isAdmin));
    }, [isProtected, isAdminPage, userContext, router, mediaId, mediaType]);

    const layouts = {
        [layoutTypes.empty]: children,
        [layoutTypes.simple]: <SimpleLayout isAdminPage={isAdminPage}>{children}</SimpleLayout>,
        [layoutTypes.default]: <Layout isAdminPage={isAdminPage}>{children}</Layout>,
    };

    const renderLayout = layouts[layoutType] || layouts[layoutTypes.default];

    return (
        <>
            <Meta meta={getMeta?.(pageProps, cms) || {}} schemaJson={getSchemaJson?.(pageProps, cms) || {}} />
            {isLoading ? <PageLoader /> : isAllowed ? renderLayout : <div />}
        </>
    );
};

Page.propTypes = {
    isProtected: PropTypes.bool,
    layoutType: PropTypes.oneOf(Object.values(layoutTypes)),
    isAdminPage: PropTypes.bool,
    pageProps: PropTypes.object,
    getMeta: PropTypes.func,
    getSchemaJson: PropTypes.func,
};

export default Page;
