import React from 'react';

import { Drawer as MuiDrawer } from '@mui/material';

const Drawer = React.forwardRef(({ children, ...props }, ref) => {
    return (
        <MuiDrawer ref={ref} {...props}>
            {children}
        </MuiDrawer>
    );
});

export default Drawer;
