const palette = {
    mode: 'light',
    primary: {
        main: '#F06734',
        dark: '#E94713',
        gradient: 'linear-gradient(315deg, #E94713 0%, #FD8A3E 100%)',
        gradientReversed: 'linear-gradient(0deg, #E94713 0%, #FD8A3E  100%)',
        gradientHover: 'linear-gradient(315deg, #E94713 0%, #E94713 100%)',
    },
    secondary: {
        main: '#426071',
        dark: '#2F4450',
    },
    success: {
        main: '#77a864',
        dark: '#497938',
        light: '#a7da92',
        contrastText: '#FFFFFF',
    },
    text: {
        primary: '#2F4450',
        secondary: '#4F7185',
    },
    background: {
        default: '#FFFFFF',
        paper: '#F2F6F8',
        appBar: '#F2F6F8AB',
        light: '#F6F8F9',
        backdrop: '#FFFFFFEF',
        warning: 'rgba(230, 187, 79, 0.1)',
    },
    input: {
        background: '#F2F6F8',
        border: '#DFE7EC',
        hoverBackground: '#F2F6F8',
        hoverBorder: '#DFE7EC',
        focusedBackground: '#F6F8F9',
        focusedBorder: '#DFE7EC',
    },
    divider: '#DFE7EC',
    dividerLight: '#E8EEF2',
};

const lightTheme = {
    palette,
};

export default lightTheme;
