import React, { useRef, useState } from 'react';

import MatureContentPopup from './MatureContentPopup';
import MatureContext from './MatureContext';

const MatureContextProvider = ({ children }) => {
    const callbackRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const openMatureContentPopup = callback => {
        callbackRef.current = callback;
        setIsOpen(true);
    };

    const closeMatureContentPopup = () => {
        callbackRef.current = null;
        setIsOpen(false);
    };

    return (
        <MatureContext.Provider
            value={{
                isOpen,
                openMatureContentPopup,
                closeMatureContentPopup,
            }}
        >
            {children}
            <MatureContentPopup isOpen={isOpen} callback={callbackRef.current} onClose={closeMatureContentPopup} />
        </MatureContext.Provider>
    );
};

export default MatureContextProvider;
