/* eslint-disable max-lines */
import { useEffect, useMemo, useRef } from 'react';

import useCms from '@components/Cms/useCms';
import { useAnalytics } from '@components/Providers/AnalyticsContextProvider';

const gemiusPlayerId = '192038';

const gemiusStringMaxLength = 64;

const getPremiereDate = publishedAt => {
    if (!publishedAt) {
        return null;
    }

    const date = new Date(publishedAt * 1000);
    const month = date.getMonth() + 1;
    const displayMonth = month.toString().padStart(2, '0');
    const displayYear = date.getFullYear();
    const day = date.getDate();
    const displayDay = day.toString().padStart(2, '0');
    return `${displayYear}${displayMonth}${displayDay}`;
};

const useGemiusEvents = ({ rmpRef, rmpContainerRef }) => {
    const gemiusRef = useRef();
    const cms = useCms();
    const currentTrackRef = useRef();
    const { logAdFullyPlayed } = useAnalytics();

    useEffect(() => {
        const gemiusCheckerInterval = setInterval(() => {
            if (window?.GemiusPlayer) {
                clearInterval(gemiusCheckerInterval);
                gemiusRef.current = new GemiusPlayer(gemiusPlayerId, process.env.NEXT_PUBLIC_GEMIUS_STREAMING_ID, {
                    currentDomain: window.location.hostname,
                });
            }
        });

        return () => {
            clearInterval(gemiusCheckerInterval);
        };
    }, []);

    const gemiusEvents = useMemo(() => {
        const getPlayerVolume = () => Math.round(rmpRef.current.getVolume() * 100) || -1;
        const getPlayerTime = () => rmpRef.current.getCurrentTime() / 1000;

        const dispatchProgramEvent = (type, params) => {
            if (!gemiusRef.current) {
                return;
            }

            if (!process.env.NEXT_PUBLIC_TRACKING_ENABLED) {
                console.log('gemius program', type);
                return;
            }

            if (currentTrackRef?.current?.id) {
                gemiusRef.current.programEvent(currentTrackRef.current.id, getPlayerTime(), type, params);
            }
        };

        const dispatchAdEvent = (type, params) => {
            if (!gemiusRef.current) {
                return;
            }

            if (!process.env.NEXT_PUBLIC_TRACKING_ENABLED) {
                console.log('gemius ad', type);
                return;
            }

            gemiusRef.current.adEvent(
                currentTrackRef.current.id,
                rmpRef.current.getAdID(),
                getPlayerTime(),
                type,
                params
            );
        };

        const handlers = {
            play: () => {
                dispatchProgramEvent('play');
            },
            pause: () => {
                dispatchProgramEvent('pause');
            },
            seeked: () => {
                dispatchProgramEvent('seek');
            },
            ended: () => {
                if ((currentTrackRef.current?.duration?.seconds || 0) - (getPlayerTime() || 0) < 1) {
                    dispatchProgramEvent('complete');
                }
            },
            adskipped: () => {
                dispatchAdEvent('skip');
            },
            adstarted: () => {
                dispatchProgramEvent('break');
                const newAdParams = {
                    adName: rmpRef.current.getAdTitle()?.substring(0, gemiusStringMaxLength),
                    adDuration: Math.round(rmpRef.current.getAdDuration() / 1000),
                    // adType // TODO ez mi legyen? break, promo, spot, sponsor
                    // campaignClassification // TODO ez mit jelent? Hierarchical classification of the campaign, including: campaign name, brand, producer, slash separated
                    adFormat: 2, // 2 = audio
                    volume: getPlayerVolume(),
                };
                gemiusRef.current.newAd(rmpRef.current.getAdID(), newAdParams);
                dispatchAdEvent('play', { autoPlay: true });
            },
            adcomplete: () => {
                logAdFullyPlayed(currentTrackRef?.current);
                dispatchAdEvent('complete');
            },
            adpaused: () => {
                dispatchAdEvent('pause');
            },
            adresumed: () => {
                dispatchAdEvent('play', { autoPlay: false });
            },
            advolumechanged: () => {
                dispatchAdEvent('chngVol', { volume: getPlayerVolume() });
            },
            volumechange: () => {
                // TODO ez mintha a legelején is meghívódna vagy a legvégén, seemingly feleslegesen
                if (!rmpRef.current?.getAdOnStage?.()) {
                    dispatchProgramEvent('chngVol', { volume: getPlayerVolume() });
                }
            },
        };
        const keys = Object.keys(handlers);
        const handleEvent = event => {
            const handler = handlers[event.type];
            handler(event);
        };

        const addCurrentTrack = currentTrack => {
            currentTrackRef.current = currentTrack;
        };

        const addHandlers = currentTrack => {
            if (!gemiusRef.current) {
                return;
            }

            currentTrackRef.current = currentTrack;
            for (const event of keys) {
                rmpContainerRef.current.addEventListener(event, handleEvent);
            }
        };

        const removeHandlers = () => {
            if (!gemiusRef.current) {
                return;
            }

            for (const event of keys) {
                rmpContainerRef.current.removeEventListener(event, handleEvent);
            }
        };

        const handleClose = () => {
            if (!gemiusRef.current) {
                return;
            }

            if (rmpRef.current && !rmpRef.current.ended()) {
                if (rmpRef.current.getAdOnStage()) {
                    dispatchAdEvent('close');
                }

                dispatchProgramEvent('close');
            }
        };

        const newProgram = () => {
            if (!gemiusRef.current) {
                return;
            }

            const programParams = {
                programName: currentTrackRef.current?.title?.substring(0, gemiusStringMaxLength),
                programDuration: Math.round(currentTrackRef.current?.duration?.seconds || 0),
                programType: 'audio',
                transmissionType: 1,
                series: currentTrackRef.current?.parentMedia?.title?.substring(0, gemiusStringMaxLength),
                programSeason: `${currentTrackRef.current?.season}. ${cms[597]}`.substring(0, gemiusStringMaxLength),
                programProducer: currentTrackRef.current?.hosts?.[0]?.title?.substring(0, gemiusStringMaxLength),
                premiereDate: getPremiereDate(currentTrackRef.current?.publishedAt),
                categoryAdIds: (currentTrackRef.current?.categories || []).map(
                    ({ properties }) => properties?.categoryAdId
                ),
                // programThematicCategory // TODO erről van elvileg doksi mik lehetnek az értékek, csak nem tiszta hol
            };
            gemiusRef.current.newProgram(currentTrackRef.current?.id, programParams);
        };

        const setVideoObject = () => {
            if (!gemiusRef.current) {
                return;
            }

            gemiusRef.current.setVideoObject(rmpRef.current.getHtmlMediaElement());
        };

        return {
            handlers,
            keys,
            addHandlers,
            removeHandlers,
            dispatchProgramEvent,
            dispatchAdEvent,
            handleClose,
            newProgram,
            setVideoObject,
            addCurrentTrack,
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cms]);

    return gemiusEvents;
};

export default useGemiusEvents;
