import React, { useCallback, useEffect, useRef, useState } from 'react';

import { toastApiError } from 'utils/toast';

import useApi from '@components/api/useApi';

import AudioUploadContext from './AudioUploadContext';

const initialContext = {
    uploadQueueMeta: [],
    currentIndex: -1,
    uploading: false,
};

const AudioUploadContextProvider = ({ children }) => {
    const [context, setContext] = useState(initialContext);
    const uploadQueueRef = useRef([]);
    const api = useApi();
    const queueLength = context.uploadQueueMeta.length;

    const reset = useCallback(() => {
        uploadQueueRef.current = [];
        setContext(initialContext);
    }, []);

    const progressCallback = useCallback(data => {
        const { isCompleted, currentPart, totalParts, error } = data;

        setContext(prev => {
            const nextIndex = prev.currentIndex + 1;
            const hasNext = !!prev.uploadQueueMeta[nextIndex];
            const newCurrentIndex = hasNext && isCompleted ? nextIndex : prev.currentIndex;

            if (error) {
                toastApiError(error);
            }

            if (!error && isCompleted) {
                prev.uploadQueueMeta[prev.currentIndex].onSuccess();
            }

            return {
                ...prev,
                uploadQueueMeta: prev.uploadQueueMeta.map((meta, index) => {
                    if (index === prev.currentIndex) {
                        return {
                            ...meta,
                            isStarted: true,
                            isCompleted,
                            error,
                            progressPercent: Math.round((currentPart / totalParts) * 100),
                        };
                    }

                    return meta;
                }),
                currentIndex: newCurrentIndex,
                uploading: hasNext || !isCompleted,
            };
        });
    }, []);

    const consumeQueue = useCallback((file, meta) => {
        api.uploadAsset(file, meta.policy, progressCallback, meta.entityId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const queueUpload = useCallback((files, entityId, viewLink, policy, onSuccess) => {
        uploadQueueRef.current = [...uploadQueueRef.current, ...files];
        setContext(prev => ({
            ...prev,
            uploadQueueMeta: [
                ...prev.uploadQueueMeta,
                ...files.map(file => ({
                    name: file.name || '',
                    progressPercent: 0,
                    isStarted: false,
                    isCompleted: false,
                    error: null,
                    entityId,
                    viewLink,
                    policy,
                    onSuccess,
                })),
            ],
            currentIndex: prev.uploading ? prev.currentIndex : prev.currentIndex + 1,
            uploading: true,
        }));
    }, []);

    const index = context.currentIndex;

    useEffect(() => {
        if (index > -1 && index < queueLength) {
            const file = uploadQueueRef.current[index];
            const meta = context.uploadQueueMeta[index];
            consumeQueue(file, meta);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index]);

    return (
        <AudioUploadContext.Provider value={{ ...context, queueUpload, reset }}>{children}</AudioUploadContext.Provider>
    );
};

export default AudioUploadContextProvider;
