import React, { useCallback, useState } from 'react';

import { TableBody as MuiTableBody, TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import ClickAwayListener from '@ui/ClickAwayListener';

import tablePropTypes from './Table.propTypes';
import TableRow from './TableRow';

const getHandler = callback => event => {
    event.preventDefault();
    event.stopPropagation();
    return callback();
};

const TableBody = ({
    columns,
    hasRows,
    rows,
    rowKey,
    editable,
    selectedKeys,
    orderable,
    onRowClick,
    onDelete,
    onSave,
    onReorder,
    onSelect,
    selectable,
    size,
    hover = true,
}) => {
    const [orderingKey, setOrderingKey] = useState(null);
    const [editingKey, setEditingKey] = useState(null);
    const formContext = useFormContext();

    const getRow = useCallback(
        (row, index, isEditing) =>
            (columns || []).map(({ id, render, sx }) => (
                <TableCell sx={sx} key={id}>
                    {render(row, index, isEditing)}
                </TableCell>
            )),
        [columns]
    );

    const body = (rows || []).map((row, index) => {
        const key = row[rowKey];
        const isEditing = editingKey === key;
        const selected = (selectedKeys || []).includes(key);
        const isOrderTarget = orderingKey === key;
        return (
            <TableRow
                hover={hover}
                key={key}
                size={size}
                orderable={orderable}
                selectable={selectable}
                selected={selected}
                editable={editable}
                editing={isEditing}
                onEdit={getHandler(() => {
                    formContext.reset(row);
                    setEditingKey(key);
                })}
                onEditCancel={getHandler(() => {
                    setEditingKey(null);
                })}
                onSave={getHandler(async () => {
                    await onSave(formContext.getValues());
                    setEditingKey(null);
                })}
                onDelete={getHandler(() => {
                    onDelete(row);
                })}
                onSelect={() => onSelect(row, !selected)}
                ordering={!!orderingKey}
                isOrderTarget={isOrderTarget}
                clickable={!!onRowClick}
                onOrder={getHandler(() => {
                    setOrderingKey(key);
                })}
                onClick={async () => {
                    if (!orderingKey) {
                        onRowClick && onRowClick(row);
                    } else {
                        await onReorder(orderingKey, key);
                        setOrderingKey(null);
                    }
                }}
            >
                {getRow(row, index, isEditing)}
            </TableRow>
        );
    });

    const handleTableContainerClickAway = useCallback(() => {
        setOrderingKey(null);
    }, []);

    return (
        <ClickAwayListener onClickAway={handleTableContainerClickAway}>
            <MuiTableBody>{hasRows && body}</MuiTableBody>
        </ClickAwayListener>
    );
};

TableBody.propTypes = {
    hasRows: PropTypes.bool,
    columns: tablePropTypes.columns,
    rows: tablePropTypes.rows,
    rowKey: tablePropTypes.rowKey,
    orderable: tablePropTypes.orderable,
    editable: PropTypes.bool,
    onRowClick: tablePropTypes.onRowClick,
    onReorder: tablePropTypes.onReorder,
    onSave: tablePropTypes.onSave,
    onDelete: tablePropTypes.onDelete,
    onSelect: tablePropTypes.onSelect,
    selectable: tablePropTypes.selectable,
    selectedKeys: tablePropTypes.selectedKeys,
    size: tablePropTypes.size,
    hover: PropTypes.bool,
};

export default React.memo(TableBody);
