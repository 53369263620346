import React from 'react';

import { Dialog as MuiDialog } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';

import { Close as CloseIcon } from '@mui/icons-material';

import useCms from '@components/Cms/useCms';

import IconButton from './IconButton';
import Tooltip from './Tooltip';

const DialogStyled = styled(MuiDialog, { shouldForwardProp: prop => prop !== 'minWidth' && prop !== 'maxHeight' })(
    ({ theme, minWidth, maxHeight }) => ({
        '& .MuiDialog-paper': {
            position: 'relative',
            ...(minWidth && { width: minWidth }),
            ...(maxHeight && { maxHeight: maxHeight }),
            maxWidth: '100%',
            [theme.breakpoints.down('sm')]: {
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: 0,
                width: '100%',
                height: '100vh',
                maxHeight: '100vh',
            },
        },
    })
);

const Dialog = ({ children, onClose, minWidth = 450, maxHeight, ...props }) => {
    const cms = useCms();

    return (
        <DialogStyled {...props} maxHeight={maxHeight} minWidth={minWidth} onClose={onClose}>
            {onClose && (
                <Tooltip title={cms[141]}>
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                        }}
                        onClick={onClose}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            )}
            {children}
        </DialogStyled>
    );
};

Dialog.propTypes = {
    onClose: PropTypes.func,
    minWidth: PropTypes.string,
    maxHeight: PropTypes.string,
};

export default Dialog;
