import React from 'react';

import { ListItemIcon as MuiListItemIcon } from '@mui/material';

const ListItemIcon = props => {
    const { children, ...rest } = props;
    return <MuiListItemIcon {...rest}>{children}</MuiListItemIcon>;
};

export default ListItemIcon;
