import React from 'react';

import image from '@images/landing/img.png';
import background from '@images/landing/landing-background.jpg';
import logo from '@images/landing/logo.svg';
import { styled, useTheme } from '@mui/system';
import Image from 'next/image';

import Box from '@ui/Box';
import Grid from '@ui/Grid';
import { H1 } from '@ui/heading';
import Typography from '@ui/Typography';

const Container = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${image.src}), url(${background.src})`,
    backgroundSize: '200px, cover',
    backgroundPosition: 'bottom right, center center',
    backgroundRepeat: 'no-repeat, no-repeat',
    position: 'absolute',
    height: '100vh',
    width: '100vw',

    [theme.breakpoints.up('sm')]: {
        backgroundSize: '400px, cover',
    },

    [theme.breakpoints.up('md')]: {
        backgroundSize: '500px, cover',
    },

    [theme.breakpoints.up('lg')]: {
        backgroundSize: '600px, cover',
    },
}));

const Content = styled(Grid)(({ theme }) => ({
    height: '100vh',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    padding: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
        paddingTop: 0,
    },
}));

const Logo = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
    borderRadius: '50%',
    width: 64,
    height: 64,
    marginBottom: theme.spacing(1),
}));

const MaintenancePage = () => {
    const theme = useTheme();
    return (
        <Container>
            <Grid container sx={{ height: '100vh' }}>
                <Content item xs={12} md={8}>
                    <Box sx={{ textAlign: 'center' }}>
                        <H1 sx={{ color: '#FFFFFF', textShadow: theme.shadows[4] }}>Karbantartás</H1>
                        <Typography variant="body1" sx={{ marginTop: 2, color: '#FFFFFF' }}>
                            Az oldal karbantartás alatt van, nézz vissza később!
                        </Typography>
                        <Logo>
                            <LogoWrapper>
                                <Image src={logo} alt="PodPad" />
                            </LogoWrapper>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: '#FFFFFF',
                                    textShadow: theme.shadows[1],
                                    fontSize: '2rem',
                                    mb: 0,
                                    lineHeight: 1,
                                }}
                            >
                                <strong>Pod</strong>Pad
                            </Typography>
                            <Typography variant="body1" sx={{ color: '#FFFFFF' }}>
                                for all ears
                            </Typography>
                        </Logo>
                    </Box>
                </Content>
                <Grid item xs={12} md={4}></Grid>
            </Grid>
        </Container>
    );
};

export default MaintenancePage;
