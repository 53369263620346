import React, { useState } from 'react';

import { styled } from '@mui/system';

import { VolumeDownRounded, VolumeOffRounded, VolumeUpRounded } from '@mui/icons-material';

import Box from '@ui/Box';
import IconButton from '@ui/IconButton';
import Slider from '@ui/inputs/Slider';
import Menu from '@ui/Menu';
import Tooltip from '@ui/Tooltip';

import useCms from '@components/Cms/useCms';

import useRadiantPlayerContext from '../useRadiantPlayer';

const Container = styled(Box)({
    flexGrow: 0,
    flexShrink: 0,
});

const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 20,
        ul: {
            paddingBottom: 0,
            paddingTop: theme.spacing(3),
        },
    },
}));

const VolumeIcon = ({ volume, muted, ...rest }) => {
    return (
        <IconButton color="secondary" {...rest}>
            {(volume === 0 || muted) && <VolumeOffRounded />}
            {volume > 0 && volume < 0.5 && !muted && <VolumeDownRounded />}
            {volume >= 0.5 && !muted && <VolumeUpRounded />}
        </IconButton>
    );
};

const VolumeBtn = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { volume, muted, setVolume, setMute, getIsMobile } = useRadiantPlayerContext();
    const [initialVolume, setInitialVolume] = useState(0.5);
    const cms = useCms();
    const handleClick = event => {
        if (getIsMobile()) {
            setMute(!muted);
        } else {
            setInitialVolume(muted ? 0 : volume);
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMute = () => {
        setMute(true);
        handleClose();
    };

    const handleVolumeChange = ev => {
        const newVolume = ev?.target?.value;
        setMute(false);
        setVolume(newVolume);
    };

    function preventHorizontalKeyboardNavigation(event) {
        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            event.preventDefault();
        }
    }

    return (
        <>
            <Tooltip title={cms[2020]}>
                <Container>
                    <VolumeIcon volume={volume} muted={muted} aria-controls="volume-menu" onClick={handleClick} />
                </Container>
            </Tooltip>
            <StyledMenu
                dense
                sx={{ zIndex: 6001, borderRadius: 20 }}
                id="volume-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'volume-menu',
                    sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                }}
            >
                <li>
                    <Box sx={{ height: 125 }}>
                        <Slider
                            sx={{
                                '& input[type="range"]': {
                                    WebkitAppearance: 'slider-vertical',
                                },
                            }}
                            orientation="vertical"
                            defaultValue={initialVolume}
                            aria-label="Temperature"
                            min={0}
                            max={1}
                            step={0.05}
                            onChange={handleVolumeChange}
                            onChangeCommitted={handleClose}
                            onKeyDown={preventHorizontalKeyboardNavigation}
                        />
                    </Box>
                </li>
                <li>
                    <VolumeIcon volume={volume} muted={muted} color="secondary" sx={{ mt: 1 }} onClick={handleMute} />
                </li>
            </StyledMenu>
        </>
    );
};

export default VolumeBtn;
