import React from 'react';

import { Table as MuiTable, TableContainer } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import Empty from '@ui/Empty';
import LinearProgress from '@ui/LinearProgress';
import Paper from '@ui/Paper';

import tablePropTypes from './Table.propTypes';
import TableBody from './TableBody';
import TableFooter from './TableFooter';
import TableHead from './TableHead';

const Table = ({
    rows,
    loading,
    emptyText,
    columns,
    rowKey,
    rowsPerPage,
    page,
    totalCount,
    hasNextPage,
    hasPreviousPage,
    onPageChange,
    onRowsPerPageChange,
    onLoadMoreClick,
    onRowClick,
    variant,
    hideTableFooter,
    emptyAction,
    className,
    orderable,
    onReorder,
    editable,
    onSave,
    onDelete,
    onSelect,
    selectable,
    selectedKeys,
    size,
    onSelectAll,
    hideTableHeader,
    hover,
}) => {
    const form = useForm();
    const rowCount = rows?.length;
    const hasRows = rowCount > 0;

    return (
        <>
            <Paper className={className}>
                <FormProvider {...form}>
                    <TableContainer>
                        <MuiTable size={size || 'small'}>
                            {!hideTableHeader && (
                                <TableHead
                                    selectable={selectable}
                                    editable={editable}
                                    columns={columns}
                                    orderable={orderable}
                                    onSelectAll={checked => onSelectAll(checked ? rows : [])}
                                    size={size}
                                    allSelected={rows?.length > 0 && selectedKeys?.length === rows?.length}
                                />
                            )}
                            <TableBody
                                size={size}
                                editable={editable}
                                selectable={selectable}
                                columns={columns}
                                rows={rows}
                                rowKey={rowKey}
                                orderable={orderable}
                                onRowClick={onRowClick}
                                onReorder={onReorder}
                                onSave={onSave}
                                onDelete={onDelete}
                                onSelect={onSelect}
                                selectedKeys={selectedKeys}
                                hasRows={hasRows}
                                hover={hover}
                            />
                        </MuiTable>
                    </TableContainer>
                </FormProvider>
                {!hasRows && !loading && <Empty emptyText={emptyText} action={emptyAction} />}
                {loading && <LinearProgress />}
            </Paper>

            {!hideTableFooter && (
                <TableFooter
                    variant={variant}
                    totalCount={totalCount}
                    rowCount={rowCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    loading={loading}
                    hasPreviousPage={hasPreviousPage}
                    hasNextPage={hasNextPage}
                    hasRows={hasRows}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    onLoadMoreClick={onLoadMoreClick}
                />
            )}
        </>
    );
};

Table.defaultProps = {
    variant: 'paginated',
};

Table.propTypes = tablePropTypes;

export default Table;
