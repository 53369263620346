import React from 'react';

import { styled } from '@mui/system';

import { PauseRounded, PlayArrowRounded } from '@mui/icons-material';

import CircularProgress from '@ui/CircularProgress';
import FilledIconButton from '@ui/FilledIconButton';

import useRadiantPlayerContext from '../useRadiantPlayer';

const LoadingContainer = styled('div')(({ theme }) => ({
    marginRight: theme.spacing(2),
    width: 40,
    height: 40,
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0.3,
}));

const PlayBtn = () => {
    const { togglePlayPause, isPlaying, loading } = useRadiantPlayerContext();
    return (
        <>
            <FilledIconButton size="small" onClick={togglePlayPause} disabled={loading}>
                {isPlaying ? <PauseRounded /> : <PlayArrowRounded />}
                {loading && (
                    <LoadingContainer>
                        <CircularProgress color="secondary" />
                    </LoadingContainer>
                )}
            </FilledIconButton>
        </>
    );
};

export default PlayBtn;
