import React from 'react';

import { ListSubheader as MuiListSubheader } from '@mui/material';

const ListSubheader = props => {
    const { children, ...rest } = props;
    return <MuiListSubheader {...rest}>{children}</MuiListSubheader>;
};

export default ListSubheader;
