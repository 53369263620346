import React from 'react';

import { Collapse as MuiCollapse } from '@mui/material';

const Collapse = ({ children, ...props }) => {
    return (
        <MuiCollapse timeout="auto" unmountOnExit {...props}>
            {children}
        </MuiCollapse>
    );
};

export default Collapse;
