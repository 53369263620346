import React, { useMemo } from 'react';

import { styled } from '@mui/system';
import { useIllustration } from '@utils/illustrations';
import Image from 'next/image';
import PropTypes from 'prop-types';

import Box from '@ui/Box';
import Dialog from '@ui/Dialog';
import DialogContent from '@ui/DialogContent';
import DialogTitle from '@ui/DialogTitle';
import Typography from '@ui/Typography';

import useCms from '@components/Cms/useCms';
import AuthButtons from '@components/page/AuthButtons';

const FeatureList = styled('ul')(({ theme }) => ({
    textAlign: 'left',
    marginBottom: theme.spacing(4),
}));
const FeatureListItem = styled('li')(() => ({}));

const ImageWrapper = styled(Box)({
    width: 180,
    height: 180,
    maxHeight: 180,
    maxWidth: 180,
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: -24,
});

const RegisterDialog = ({ isOpen, onClose }) => {
    const cms = useCms();
    const { unlockFeatures } = useIllustration();

    const features = useMemo(
        () =>
            [cms[496], cms[497], cms[498], cms[499]].map((text, index) => (
                <FeatureListItem key={index}>
                    <Typography>{text}</Typography>
                </FeatureListItem>
            )),
        [cms]
    );

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{cms[2014]}</DialogTitle>
            <DialogContent>
                <Box textAlign="center">
                    <ImageWrapper>
                        <Image src={unlockFeatures} layout="fill" alt="" />
                    </ImageWrapper>
                    <Typography>{cms[495]}</Typography>
                    <FeatureList>{features}</FeatureList>
                    <AuthButtons text onRegisterClick={handleClose} onLoginClick={handleClose} />
                </Box>
            </DialogContent>
        </Dialog>
    );
};

RegisterDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

export default RegisterDialog;
