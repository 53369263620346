import React from 'react';

import { AppBar as MuiAppBar } from '@mui/material';
import { useTheme } from '@mui/system';

const getStyles = theme => ({
    backgroundColor: theme.palette.background.appBar,
    backgroundImage: 'none',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.dividerLight}`,
    backdropFilter: 'blur(15px)',
});

const AppBar = props => {
    const theme = useTheme();
    const styles = getStyles(theme);
    const { children, sx, ...rest } = props;
    return (
        <MuiAppBar {...rest} color="transparent" sx={{ ...sx, ...styles }}>
            {children}
        </MuiAppBar>
    );
};

export default AppBar;
