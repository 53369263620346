import { useMemo, useState } from 'react';

export const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

export const firstLastDebounce = (func, timeout = 300) => {
    let timer;
    let firstArgs = null;
    return (...args) => {
        if (!firstArgs) {
            firstArgs = args;
        }

        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, [{ firstArgs, lastArgs: args }]);
            firstArgs = null;
        }, timeout);
    };
};

export const debounceLeading = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        if (!timer) {
            func.apply(this, args);
            timer = setTimeout(() => {
                timer = undefined;
            }, timeout);
        }
    };
};

export const useDebouncedState = (initial, delay) => {
    const [state, setState] = useState(initial);

    const debouncedSetState = useMemo(() => debounce(setState, delay), [delay]);

    return [state, debouncedSetState];
};
