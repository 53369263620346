import links from './links/links.json';
import huLoc from './localization/hu.json';
import permissions from './permissions/permissions.json';

export const getCms = () => huLoc?.keys;

export const getLinks = (page, ...params) => {
    const link = links?.[page] || '/';
    if (!params.length) {
        return link;
    }

    let formattedText = link;
    params.forEach((t, i) => {
        if (t != null && typeof t !== 'object') {
            formattedText = formattedText.replace(`{${i}}`, t.toString());
        }
    });

    return formattedText;
};

export const getPermissionMap = () =>
    permissions.reduce((acc, curr) => {
        return {
            ...acc,
            [curr.canonicalName]: curr,
        };
    }, {});

export const getPermissionOptions = hideAdmin =>
    permissions
        .filter(({ canonicalName }) => (hideAdmin ? canonicalName !== 'admin' : true))
        .map(({ canonicalName, name }) => {
            return {
                value: canonicalName,
                name,
            };
        });
