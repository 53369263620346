import React from 'react';

import Typography from './../Typography';

export const H6 = props => {
    const { children, ...rest } = props;
    return (
        <Typography variant="h6" {...rest}>
            {children}
        </Typography>
    );
};

export const H5 = props => {
    const { children, ...rest } = props;
    return (
        <Typography variant="h5" {...rest}>
            {children}
        </Typography>
    );
};

export const H4 = props => {
    const { children, ...rest } = props;
    return (
        <Typography variant="h4" {...rest}>
            {children}
        </Typography>
    );
};

export const H3 = props => {
    const { children, ...rest } = props;
    return (
        <Typography variant="h3" {...rest}>
            {children}
        </Typography>
    );
};

export const H2 = props => {
    const { children, ...rest } = props;
    return (
        <Typography variant="h2" {...rest}>
            {children}
        </Typography>
    );
};

export const H1 = props => {
    const { children, ...rest } = props;
    return (
        <Typography variant="h1" {...rest}>
            {children}
        </Typography>
    );
};
