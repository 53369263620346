import React from 'react';

import { NextLinkComposed } from '@ui/link/Link';
import ListItemButton from '@ui/ListItemButton';

const ListItemButtonLink = props => {
    const { children, ...rest } = props;
    return (
        <ListItemButton component={NextLinkComposed} {...rest}>
            {children}
        </ListItemButton>
    );
};

export default ListItemButtonLink;
