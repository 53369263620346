/* eslint-disable max-lines */
import React, { useMemo, useState } from 'react';

import { styled } from '@mui/system';
import { useAsyncHandler } from '@utils/hooks';
import { useTableSelection } from '@utils/table';
import PropTypes from 'prop-types';
import useSWR from 'swr';

import Box from '@ui/Box';
import Dialog from '@ui/Dialog';
import DialogActions from '@ui/DialogActions';
import DialogContent from '@ui/DialogContent';
import DialogTitle from '@ui/DialogTitle';
import Button from '@ui/inputs/Button';
import Table from '@ui/Table/Table';
import Typography from '@ui/Typography';

import useApi from '@components/api/useApi';
import useCms from '@components/Cms/useCms';

import EpisodeCompactCard from './EpisodeCompactCard';

const ReasonList = styled('ul')(({ theme }) => ({
    textAlign: 'left',
    marginBottom: theme.spacing(4),
}));
const ReasonListItem = styled('li')(() => ({}));

const ReportModal = ({ onClose, mediaData, playingMediaTime }) => {
    const cms = useCms();
    const api = useApi();
    const [step, setStep] = useState(0);
    const [selectedReasons, handleSelect] = useTableSelection({
        keyName: 'reason',
    });
    const handleSelectReason = (row, isSelecting) => {
        if (selectedReasons.length >= 2 && isSelecting) {
            return;
        }

        handleSelect(row, isSelecting);
    };

    const { data, isValidating: reportReasonsLoading } = useSWR('/media/reports/reasons');
    const [handleReport, reportLoading] = useAsyncHandler(
        {
            handler: async () => {
                await api.post('/media/reports', {
                    playingMediaId: mediaData.id,
                    playingMediaTime,
                    reasons: selectedReasons,
                });
            },
            onSuccess: () => setStep(2),
            stopLoadingOnSuccess: true,
            muteNotifications: true,
        },
        [mediaData, playingMediaTime, selectedReasons]
    );
    const reportReasons = (data || []).map(reason => ({ reason }));

    const tableColumns = useMemo(
        () => [
            {
                id: 'reason',
                title: cms[825],
                render: ({ reason }) => <Typography>{reason}</Typography>,
            },
        ],
        [cms]
    );

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>{cms[832]}</DialogTitle>
            <DialogContent>
                <Box mb={2}>
                    <EpisodeCompactCard mediaData={mediaData} noAction />
                </Box>
                {step === 0 && (
                    <>
                        <Typography sx={{ mb: 2 }}>{cms[833]}</Typography>
                        <Table
                            selectable
                            hideTableFooter
                            hideTableHeader
                            rowKey="reason"
                            emptyText={cms[824]}
                            columns={tableColumns}
                            loading={reportReasonsLoading}
                            rows={reportReasons}
                            totalCount={reportReasons.lenght}
                            variant="infinite"
                            onSelect={handleSelectReason}
                            selectedKeys={selectedReasons}
                            size="small"
                            hover={false}
                        />
                    </>
                )}
                {step === 1 && (
                    <>
                        <Typography>{cms[826]}</Typography>
                        <ReasonList>
                            {selectedReasons.map((text, index) => (
                                <ReasonListItem key={index}>
                                    <Typography>{text}</Typography>
                                </ReasonListItem>
                            ))}
                        </ReasonList>
                    </>
                )}
                {step === 2 && <Typography>{cms[829]}</Typography>}
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    variant="text"
                    fullWidth={false}
                    loading={reportLoading}
                    disabled={reportLoading}
                    onClick={step === 0 || step === 2 ? onClose : () => setStep(0)}
                >
                    {cms[step === 0 || step === 2 ? 827 : 828]}
                </Button>
                {step !== 2 && (
                    <Button
                        fullWidth={false}
                        loading={reportLoading}
                        disabled={selectedReasons.length < 1 || reportLoading}
                        onClick={step === 1 ? handleReport : () => setStep(1)}
                    >
                        {cms[step === 0 ? 830 : 831]}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

ReportModal.propTypes = {
    onClose: PropTypes.func,
    playingMediaTime: PropTypes.number,
    mediaData: PropTypes.object,
};

export default ReportModal;
