import { useEffect } from 'react';

import { clearGhostToken, isGhosting } from '@utils/token';
import { toast } from 'react-toastify';

const Ghosting = () => {
    useEffect(() => {
        if (isGhosting()) {
            toast.success('You are in ghost mode', {
                autoClose: false,
                onClose: () => {
                    clearGhostToken();
                    window.location.reload();
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default Ghosting;
