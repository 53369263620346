import { useEffect } from 'react';

import * as gemius from '@lib/gemius';
import { useRouter } from 'next/router';

const useGemius = () => {
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = () => {
            gemius.pageview();
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);
};

export default useGemius;
