import React from 'react';

import { Paper as MuiPaper } from '@mui/material';

const Paper = React.forwardRef(({ children, ...props }, ref) => {
    return (
        <MuiPaper ref={ref} {...props} elevation={0}>
            {children}
        </MuiPaper>
    );
});

export default Paper;
