import React from 'react';

import { TablePagination } from '@mui/material';
import PropTypes from 'prop-types';

import Box from '@ui/Box';
import Button from '@ui/inputs/Button';
import Typography from '@ui/Typography';

import useCms from '@components/Cms/useCms';

const TableFooter = ({
    variant,
    totalCount,
    rowCount,
    rowsPerPage,
    page,
    loading,
    hasPreviousPage,
    hasNextPage,
    hasRows,
    onPageChange,
    onRowsPerPageChange,
    onLoadMoreClick,
}) => {
    const cms = useCms();

    return variant === 'paginated' ? (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount || rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            backIconButtonProps={{ disabled: loading || !hasPreviousPage }}
            nextIconButtonProps={{ disabled: loading || !hasNextPage }}
            SelectProps={{ inputProps: { disabled: loading } }}
        />
    ) : (
        hasRows && rowCount !== totalCount && (
            <Box p={2} textAlign="center">
                <Button disabled={loading} loading={loading} color="secondary" onClick={onLoadMoreClick}>
                    {cms[169]}
                </Button>
                <Typography mt={1} variant="body2">
                    {rowCount}/{totalCount}
                </Typography>
            </Box>
        )
    );
};

TableFooter.propTypes = {
    variant: PropTypes.oneOf(['paginated', 'infinite']),
    totalCount: PropTypes.number,
    rowCount: PropTypes.number,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
    loading: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
    hasNextPage: PropTypes.bool,
    hasRows: PropTypes.bool,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    onLoadMoreClick: PropTypes.func,
};

export default TableFooter;
