import React from 'react';

import { styled } from '@mui/system';
import { useIllustration } from '@utils/illustrations';
import Image from 'next/image';

import Link from '@ui/link/Link';

const Meta = styled('div')(({ theme }) => ({
    fontSize: 10,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    '& p': {
        margin: 0,
        padding: 0,
    },
    [theme.breakpoints.up('md')]: {
        width: 216,
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
}));

const ImageContainer = styled('div')(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('md')]: {
        display: 'block',
        position: 'relative',
        width: 50,
        minWidth: 50,
        height: 50,
    },
}));

const ImageStyled = styled(Image)({
    borderRadius: 5,
    position: 'relative',
    overflow: 'hidden',
});

const Texts = styled('div')(({ theme }) => ({
    marginLeft: 10,
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
        width: 216,
        textAlign: 'left',
    },
}));

const Title = styled('p')({
    fontWeight: 700,
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    display: '-webkit-box',
});

const Author = styled('p')(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const PlayerMeta = ({ track }) => {
    const { podcastImage } = useIllustration();

    return (
        <Link color="inherit" underline="none" href={`/${track?.slug}`}>
            <Meta>
                <ImageContainer>
                    <ImageStyled
                        src={track?.image || track?.parentMedia?.image || podcastImage}
                        alt={track?.title}
                        layout="fill"
                        objectFit="cover"
                    />
                </ImageContainer>
                <Texts>
                    <Title>{track?.title}</Title>
                    <Author>{track?.parentMedia?.title}</Author>
                </Texts>
            </Meta>
        </Link>
    );
};

export default PlayerMeta;
