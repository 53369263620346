/* eslint-disable max-lines */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { styled } from '@mui/system';
import * as Sentry from '@sentry/nextjs';
import { removeFromLocalStorage, saveToLocalStorage } from '@utils/storage';
import Script from 'next/script';

import Box from '@ui/Box';

import useEventSink from '@components/EventSink/useEventSink';
import ReportButton from '@components/Media/ReportButton';

import AdInfo from './components/AdInfo';
import EmbedPlayerPlayBtn from './components/EmbedMobilePlayBtn';
import PlaybackRateBtn from './components/PlaybackRateBtn';
import PlayBtn from './components/PlayBtn';
import PlayNextSwitch from './components/PlayNextSwitch';
import SeekBar from './components/SeekBar';
import VolumeBtn from './components/VolumeBtn';
import EmbedPlayerMeta from './EmbedPlayerMeta';
import EnableAutoPlayDialog from './EnableAutoPlayDialog';
import useGemiusEvents from './hooks/useGemiusEvents';
import usePlayListUtils from './hooks/usePlayListUtils';
import LiveBadge from './LiveBadge';
import PlayerMeta from './PlayerMeta';
import RadiantPlayerContext from './RadiantPlayerContext';
import { baseSettings, getAdTagUrl, getSrc, initialContext } from './radiantPlayerUtils';
import RadiantPlayerWrapper from './RadiantPlayerWrapper';

const initialAdState = {
    isAdPlaying: false,
    adSkipTimeOffset: 0,
    playingAtSeconds: 0,
    isAdSkippable: false,
    adDuration: 0,
};

const MobileHideContainer = styled(Box)({
    flexGrow: 0,
    flexShrink: 0,
    display: 'none',
    '@media screen and (min-width: 480px)': {
        display: 'block',
    },
});

const getIsMobile = () =>
    navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/Android/i);

const TRACK_COMPLETION_THRESHOLD_S = 10;

const RadiantPlayerContextProvider = ({ children, embedPlayer, promoBoxPlayer }) => {
    const [
        {
            playingAtSeconds,
            isAdPlaying,
            isAdSkippable,
            adSkipTimeOffset,
            isPlaying,
            isAutoPlayDisabled,
            currentTrack,
            playListId,
            playbackRate,
            volume,
            muted,
            loading,
            adDuration,
            autoPlayNext,
        },
        setContext,
    ] = useState(initialContext());

    const rmpRef = useRef();
    const rmpContainerRef = useRef();
    const playerContainerRef = useRef();
    const isInteractedRef = useRef(false);
    const currentTrackRef = useRef(null);
    const playListIdRef = useRef(null);
    const autoPlayNextRef = useRef(autoPlayNext);
    const timeUpdateRef = useRef();

    const { fetchNextTrack } = usePlayListUtils();

    const gemiusEvents = useGemiusEvents({ rmpRef, rmpContainerRef });

    const eventSink = useEventSink({ rmpRef, currentTrackRef, playListIdRef });

    const togglePlayPause = useCallback(() => {
        const paused = rmpRef.current.getAdOnStage() ? rmpRef.current.getAdPaused() : rmpRef.current.getPaused();

        if (paused) {
            setContext(prev => ({ ...prev, isPlaying: true }));
            rmpRef.current.play();
            eventSink.playing({ checkForAd: true });
            return;
        }

        setContext(prev => ({ ...prev, isPlaying: false }));
        rmpRef.current.pause();
        eventSink.paused({ checkForAd: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pause = useCallback(() => {
        setContext(prev => ({ ...prev, isPlaying: false }));
        // event sinket nem küldünk, mert ez csak a seeked előtt van, ui okokból kell
        rmpRef.current.pause();
    }, []);

    const trackedPause = useCallback(() => {
        setContext(prev => ({ ...prev, isPlaying: false }));
        eventSink.paused({ checkForAd: true });
        rmpRef.current.pause();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const seekToInitialStartTime = useCallback(offsetSeconds => {
        const newOffsetStartPosition = Math.round(offsetSeconds * 1000);
        const seekTo = () => {
            rmpRef.current.seekTo(newOffsetStartPosition);
            rmpContainerRef.current.removeEventListener('canplay', seekTo);
        };

        rmpContainerRef.current.addEventListener('canplay', seekTo);
    }, []);

    useEffect(() => {
        currentTrackRef.current = currentTrack;
    }, [currentTrack]);

    useEffect(() => {
        playListIdRef.current = playListId;
    }, [playListId]);

    useEffect(() => {
        timeUpdateRef.current = setInterval(() => {
            if (!rmpRef.current) {
                return;
            }

            const playingAtMs = rmpRef.current.getAdOnStage()
                ? rmpRef.current.getAdCurrentTime()
                : rmpRef.current.getCurrentTime();
            setContext(prev => ({ ...prev, playingAtSeconds: Math.round(playingAtMs / 1000) }));
            if (!rmpRef.current.getPaused()) {
                eventSink.playing({ checkForAd: true });
            }
        }, 1000);
        return () => {
            clearInterval(timeUpdateRef.current);
            gemiusEvents.handleClose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const playOnRmp = useCallback((newTrack, offsetSeconds, newPlayListId, shouldStartPlay = true) => {
        if (rmpRef.current && rmpContainerRef.current && playerContainerRef.current) {
            gemiusEvents.handleClose();
            playerContainerRef.current.removeChild(rmpContainerRef.current);
            rmpContainerRef.current = null;
            rmpRef.current.destroy();
            rmpRef.current = null;
        }

        const id = String(Math.random()).replace('.', '');
        rmpContainerRef.current = document.createElement('div');
        rmpContainerRef.current.setAttribute('id', id);
        rmpContainerRef.current.style.width = '100%';
        playerContainerRef.current.appendChild(rmpContainerRef.current);
        rmpRef.current = new RadiantMP(id);
        const initialStartTime = offsetSeconds || newTrack?.position?.currentPosition || 0;

        rmpContainerRef.current.addEventListener('ready', () => {
            const startProgram = () => {
                gemiusEvents.newProgram();
                setContext(prev => ({ ...prev, isPlaying: true }));
                rmpRef.current.play();
                eventSink.trackStarted(initialStartTime);
            };

            if (getIsMobile() || (embedPlayer && !promoBoxPlayer)) {
                if (isInteractedRef.current && shouldStartPlay) {
                    startProgram();
                    return;
                }

                setContext(prev => ({ ...prev, isAutoPlayDisabled: true }));
                return;
            }

            if (shouldStartPlay) {
                startProgram();
            }
        });
        rmpContainerRef.current.addEventListener('error', () => {
            const error = new Error('Radiant player error');
            Sentry.captureException(error, { extra: rmpRef.current.getErrorData() });
        });
        rmpContainerRef.current.addEventListener('ended', async () => {
            const durationSeconds =
                (currentTrackRef.current?.duration
                    ? currentTrackRef.current?.duration?.seconds
                    : currentTrackRef.current?.assets?.[0]?.duration?.seconds) || 0;
            if (durationSeconds - rmpRef.current.getCurrentTime() / 1000 <= TRACK_COMPLETION_THRESHOLD_S) {
                eventSink.trackCompleted();
            } else {
                eventSink.ended();
            }

            if (embedPlayer) {
                setContext(prev => ({ ...prev, isPlaying: false }));
                return;
            }

            if (!autoPlayNextRef.current || durationSeconds - rmpRef.current.getCurrentTime() / 1000 > 1) {
                setContext(prev => ({ ...prev, isPlaying: false }));
                return;
            }

            const nextTrack = await fetchNextTrack(currentTrackRef.current?.id, newPlayListId);
            if (!nextTrack) {
                setContext(prev => ({ ...prev, isPlaying: false }));
                return;
            }

            const handleSrcChanged = () => {
                rmpRef.current.loadAds(getAdTagUrl(nextTrack));
                gemiusEvents.newProgram();
                eventSink.trackStarted(0);
                setContext(prev => ({ ...prev, currentTrack: nextTrack, ...initialAdState }));
                currentTrackRef.current = newTrack;
                rmpContainerRef.current.removeEventListener('srcchanged', handleSrcChanged);
            };

            rmpContainerRef.current.addEventListener('srcchanged', handleSrcChanged);

            gemiusEvents.removeHandlers();
            gemiusEvents.addHandlers(nextTrack);

            rmpRef.current.setSrc(getSrc(nextTrack));
            setPlaybackRate(1);
        });
        rmpContainerRef.current.addEventListener('adstarted', () => {
            setContext(prev => ({
                ...prev,
                isAdPlaying: true,
                adSkipTimeOffset: rmpRef.current.getAdSkipTimeOffset(),
                adDuration: rmpRef.current.getAdDuration() / 1000,
            }));
        });
        rmpContainerRef.current.addEventListener('adskippablestatechanged', () => {
            setContext(prev => ({
                ...prev,
                isAdSkippable: rmpRef.current.getAdSkippableState(),
            }));
        });
        rmpContainerRef.current.addEventListener('aduserclose', () => {
            setContext(prev => ({ ...prev, ...initialAdState }));
        });
        rmpContainerRef.current.addEventListener('adskipped', () => {
            setContext(prev => ({ ...prev, ...initialAdState }));
        });
        rmpContainerRef.current.addEventListener('adcomplete', () => {
            setContext(prev => ({ ...prev, ...initialAdState }));
        });
        rmpContainerRef.current.addEventListener('adcontentresumerequested', () => {
            setContext(prev => ({ ...prev, ...initialAdState }));
        });
        rmpContainerRef.current.addEventListener('volumechange', () => {
            setContext(prev => ({ ...prev, volume: rmpRef.current.getVolume() }));
        });
        rmpContainerRef.current.addEventListener('loadstart', () => {
            setContext(prev => ({ ...prev, loading: true }));
        });
        rmpContainerRef.current.addEventListener('loadeddata', () => {
            setContext(prev => ({ ...prev, loading: false }));
        });
        gemiusEvents.setVideoObject();
        gemiusEvents.addHandlers(newTrack);

        seekToInitialStartTime(initialStartTime);

        rmpRef.current.init({
            ...baseSettings,
            src: getSrc(newTrack),
            adTagUrl: getAdTagUrl(newTrack),
            contentMetadata: {
                title: newTrack.title,
                poster: newTrack?.image || newTrack?.parentMedia?.image,
                album: newTrack?.parentMedia?.title,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const playTrack = useCallback((newTrack, offsetSeconds, newPlayListId) => {
        setContext(prev => ({
            ...prev,
            currentTrack: newTrack,
            playListId: newPlayListId,
            playbackRate: 1,
            ...initialAdState,
        }));
        currentTrackRef.current = newTrack;
        playOnRmp(newTrack, offsetSeconds, newPlayListId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setTrack = useCallback((newTrack, offsetSeconds, newPlayListId) => {
        setContext(prev => ({
            ...prev,
            currentTrack: newTrack,
            playbackRate: 1,
            ...initialAdState,
        }));
        currentTrackRef.current = newTrack;
        gemiusEvents.addCurrentTrack(newTrack);
        playOnRmp(newTrack, offsetSeconds, newPlayListId, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const seekTo = useCallback(seconds => {
        if (!rmpRef.current?.getReady()) {
            return;
        }

        const seekFrom = rmpRef.current.getCurrentTime() / 1000;
        setContext(prev => ({ ...prev, isPlaying: true }));
        rmpRef.current.play();
        rmpRef.current.seekTo(seconds * 1000);
        eventSink.seek(seekFrom, seconds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setPlaybackRate = useCallback(rate => {
        rmpRef.current.setPlaybackRate(rate);
        setContext(prev => ({ ...prev, playbackRate: rate }));
    }, []);

    const setVolume = vol => {
        rmpRef.current.setVolume(vol);
        setContext(prev => ({ ...prev, volume: vol }));
    };

    const setMute = muted => {
        rmpRef.current.setMute(muted);
        setContext(prev => ({ ...prev, muted: muted }));
    };

    const setAutoPlayNext = val => {
        autoPlayNextRef.current = val;
        if (val) {
            removeFromLocalStorage('disableAutoPlayNext');
        } else {
            saveToLocalStorage('disableAutoPlayNext', { value: true });
        }

        setContext(prev => ({ ...prev, autoPlayNext: val }));
    };

    const handleCloseEnableAutoPlayDialog = useCallback(() => {
        if (rmpRef.current && rmpContainerRef.current && playerContainerRef.current) {
            playerContainerRef.current.removeChild(rmpContainerRef.current);
            rmpContainerRef.current = null;
            rmpRef.current.destroy();
            rmpRef.current = null;
        }

        setContext(prev => ({ ...prev, currentTrack: null, isAutoPlayDisabled: false }));
    }, []);

    const handleEnableAutoPlay = useCallback(() => {
        isInteractedRef.current = true;
        setContext(prev => ({ ...prev, isPlaying: true, isAutoPlayDisabled: false }));
        rmpRef.current.play();
    }, []);

    const handleSkipAd = useCallback(() => {
        if (!rmpRef.current) {
            return;
        }

        rmpRef.current.skipAd();
    }, []);

    return (
        <RadiantPlayerContext.Provider
            value={{
                playTrack,
                setTrack,
                seekTo,
                togglePlayPause,
                pause,
                handleSkipAd,
                isAdPlaying,
                isAdSkippable,
                adSkipTimeOffset,
                playingAtSeconds,
                currentTrack,
                isPlaying,
                playListId,
                playbackRate,
                setPlaybackRate,
                setVolume,
                volume,
                setMute,
                getIsMobile,
                muted,
                loading,
                adDuration,
                autoPlayNext,
                setAutoPlayNext,
            }}
        >
            {children}
            <RadiantPlayerWrapper
                showPlayer={!!currentTrack}
                className={`player-type-${currentTrack?.type}`}
                embedPlayer={embedPlayer}
                promoBoxPlayer={promoBoxPlayer}
            >
                {embedPlayer ? (
                    <EmbedPlayerMeta track={currentTrack} promoBoxPlayer={promoBoxPlayer} />
                ) : (
                    <PlayerMeta track={currentTrack} />
                )}
                <Box className="controls-container">
                    {isAutoPlayDisabled ? <EmbedPlayerPlayBtn onClickHandler={handleEnableAutoPlay} /> : <PlayBtn />}
                    {isAdPlaying && <AdInfo />}
                    {currentTrack?.type === 'radio' && !isAdPlaying && !loading && (
                        <LiveBadge type={currentTrack.type} />
                    )}
                    {currentTrack?.type !== 'radio' && !isAdPlaying && !loading && <SeekBar />}
                    <Box sx={{ flexGrow: 1, flexShrink: 1 }}>
                        <div ref={playerContainerRef} />
                    </Box>
                    {currentTrack?.type === 'episode' && !embedPlayer && <PlayNextSwitch />}
                    <VolumeBtn />
                    {currentTrack?.type === 'episode' && <PlaybackRateBtn />}
                    {!embedPlayer && !isAdPlaying && currentTrack?.type === 'episode' && (
                        <MobileHideContainer>
                            <ReportButton rmpRef={rmpRef} currentTrackRef={currentTrackRef} pause={trackedPause} />
                        </MobileHideContainer>
                    )}
                </Box>
            </RadiantPlayerWrapper>
            <EnableAutoPlayDialog
                isOpen={isAutoPlayDisabled && !(embedPlayer && !promoBoxPlayer)}
                onClose={handleCloseEnableAutoPlayDialog}
                onEnable={handleEnableAutoPlay}
                small={promoBoxPlayer}
            />
            <Script src="https://gahu.hit.gemius.pl/gplayer.js" />
        </RadiantPlayerContext.Provider>
    );
};

export default RadiantPlayerContextProvider;
