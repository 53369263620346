import React from 'react';

import appStoreBadge from '@images/AppleAppStore.svg';
import playStoreBadge from '@images/GooglePlayStore.svg';
import { styled } from '@mui/system';
import Image from 'next/image';

import Box from '@ui/Box';
import Link from '@ui/link/Link';

const ImageContainer = styled(Box)(({ theme }) => ({
    overflow: 'hidden',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
}));

const ImageStyled = styled(Image)({
    position: 'relative',
    overflow: 'hidden',
});

const AppStoreBadges = () => {
    return (
        <Box>
            <ImageContainer>
                <Link
                    href="https://play.google.com/store/apps/details?id=app.podcastportal&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    target="_blank"
                >
                    <ImageStyled src={playStoreBadge} alt="Szerezd meg: Google Play" width="207px" height="55px" />
                </Link>
            </ImageContainer>
            <ImageContainer>
                <Link
                    href="https://apps.apple.com/us/app/podpad/id1601162152?itsct=apps_box_link&itscg=30200"
                    target="_blank"
                >
                    <ImageStyled src={appStoreBadge} alt="Szerezd meg: Google Play" width="207px" height="55px" />
                </Link>
            </ImageContainer>
        </Box>
    );
};

export default AppStoreBadges;
