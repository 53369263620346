import React from 'react';

import { styled, useTheme } from '@mui/system';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const StyledToastContainer = styled(ToastContainer, {
    shouldForwardProp: prop => prop !== 'muiTheme',
})(({ muiTheme }) => {
    return {
        '--toastify-color-light': muiTheme.palette.background.paper,
        '--toastify-color-dark': muiTheme.palette.background.paper,
        '--toastify-color-error': muiTheme.palette.primary.main,
        '--toastify-icon-color-error': muiTheme.palette.primary.main,
        '--toastify-color-progress-error': muiTheme.palette.primary.main,
        '--toastify-color-success': muiTheme.palette.secondary.main,
        '--toastify-icon-color-success': muiTheme.palette.secondary.main,
        '--toastify-color-progress-success': muiTheme.palette.secondary.main,
        '--toastify-text-color-light': muiTheme.palette.text.primary,
        '--toastify-text-color-dark': muiTheme.palette.text.primary,
    };
});

const WrappedToastContainer = () => {
    const theme = useTheme();
    const toastTheme = theme.palette.mode === 'dark' ? 'dark' : 'light';

    return <StyledToastContainer muiTheme={theme} theme={toastTheme} autoClose={4000} position="bottom-center" />;
};

export default WrappedToastContainer;
