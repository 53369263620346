import React from 'react';

import { ListItemButton as MuiListItemButton } from '@mui/material';

const ListItemButton = props => {
    const { children, ...rest } = props;
    return <MuiListItemButton {...rest}>{children}</MuiListItemButton>;
};

export default ListItemButton;
