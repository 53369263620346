import { useEffect } from 'react';

import { generateGuid } from '@utils/guid';
import { getDeviceId, getSessionId, setDeviceId, setSessionId } from '@utils/session';

const useIds = () => {
    useEffect(() => {
        if (typeof global?.window !== 'undefined') {
            let deviceId = getDeviceId();
            let sessionId = getSessionId();

            if (!deviceId) {
                deviceId = generateGuid();
                setDeviceId(deviceId);
            }

            if (!sessionId) {
                sessionId = generateGuid();
                setSessionId(sessionId);
            }
        }
    }, []);
};

export default useIds;
