/* eslint-disable max-lines */
import { styled } from '@mui/system';

const fixedStyle = theme => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    borderTopColor: theme.palette.divider,
    borderTopWidth: 1,
    borderTopStyle: 'solid',

    [theme.breakpoints.up('md')]: {
        zIndex: 6000,
        flexDirection: 'row',
        alignItems: 'center',
    },
});

const embedPlayerStyle = (theme, promoBoxPlayer = false) => ({
    position: 'relative',
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius,
    ...(promoBoxPlayer && {
        border: 0,
        borderRadius: 0,
    }),

    [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        zIndex: 6000,
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
});

const RadiantPlayerWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'showPlayer' && prop !== 'embedPlayer' && prop !== 'promoBoxPlayer',
})(({ theme, showPlayer, embedPlayer, promoBoxPlayer }) => ({
    zIndex: 1100,
    width: '100%',
    background: theme.palette.background.paper,
    borderTopColor: theme.palette.divider,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    visibility: showPlayer ? 'visible' : 'hidden',
    fontFamily: theme.typography.fontFamily,
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    '& *': {
        userSelect: 'none',
    },
    '.controls-container': {
        flexGrow: 1,
        flexShrink: 1,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: 44,
        [theme.breakpoints.up('md')]: {
            width: 'auto',
        },
    },
    '.rmp-container[style]': {
        display: 'flex',
        width: 'auto !important',
    },
    '.rmp-ad-container': {
        display: 'none',
    },
    ...(embedPlayer ? embedPlayerStyle(theme, promoBoxPlayer) : fixedStyle(theme)),
}));

export default RadiantPlayerWrapper;
