import React from 'react';

import { useIllustration } from '@utils/illustrations';
import Image from 'next/image';
import PropTypes from 'prop-types';

import Box from '@ui/Box';
import Dialog from '@ui/Dialog';
import DialogContent from '@ui/DialogContent';
import DialogTitle from '@ui/DialogTitle';
import Button from '@ui/inputs/Button';
import Typography from '@ui/Typography';

import useCms from '@components/Cms/useCms';
import AuthButtons from '@components/page/AuthButtons';
import useUser from '@components/User/useUser';

const MatureContentPopup = ({ isOpen, callback, onClose }) => {
    const cms = useCms();
    const { changeMature } = useUser();
    const { alert } = useIllustration();

    const handleClick = value => {
        changeMature(value);

        if (value) {
            callback();
        }

        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{cms[2013]}</DialogTitle>
            <DialogContent>
                <Box textAlign="center">
                    <Image src={alert.src} width={200} height={200} alt="" />
                    <Typography>{cms[590]}</Typography>
                    <AuthButtons text sx={{ mt: 1 }} />
                    <Typography mt={4}>{cms[593]}</Typography>
                    <Box mt={2}>
                        <Button onClick={() => handleClick(false)} sx={{ mr: 4 }}>
                            {cms[592]}
                        </Button>
                        <Button onClick={() => handleClick(true)}>{cms[591]}</Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

MatureContentPopup.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    callback: PropTypes.func,
};

export default MatureContentPopup;
