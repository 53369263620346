import { useCallback, useContext, useMemo, useRef } from 'react';

import { useAnalytics } from '@components/Providers/AnalyticsContextProvider';

import EventSinkContext from './EventSinkContext';

const useEventSink = ({ currentTrackRef, playListIdRef, rmpRef }) => {
    const eventSinkContext = useContext(EventSinkContext);
    const { logPlayStarted, logPlayContent1min, logPlayContent5min, logSeek, logPlay } = useAnalytics();
    const playedRef = useRef();

    const pushEvent = useCallback(({ eventType, checkForAd, initialStartTime, totalLengthListened }) => {
        if (!rmpRef.current || !currentTrackRef.current || (checkForAd && rmpRef.current.getAdOnStage())) {
            return;
        }

        eventSinkContext.pushEvent({
            eventType,
            mediaId: currentTrackRef.current?.id,
            playlistId: playListIdRef.current,
            time: initialStartTime || rmpRef.current.getCurrentTime() / 1000,
            totalLengthListened: totalLengthListened || 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useMemo(
        () => ({
            trackStarted(initialStartTime) {
                playedRef.current = {
                    ...playedRef.current,
                    [currentTrackRef?.current?.title]: 0,
                };
                logPlayStarted(currentTrackRef?.current);
                pushEvent({ eventType: 'started', checkForAd: false, initialStartTime });
            },
            trackCompleted() {
                pushEvent('completed');
            },
            playing({ checkForAd } = {}) {
                const mediaTitle = currentTrackRef?.current?.title;
                const totalLengthListened = playedRef?.current?.[mediaTitle] || 0;
                playedRef.current = {
                    ...playedRef.current,
                    [currentTrackRef?.current?.title]: totalLengthListened + 1,
                };

                pushEvent({ eventType: 'playing', checkForAd, totalLengthListened: 1 });

                if (totalLengthListened > 60 && !currentTrackRef?.current?.logging?.oneMin) {
                    logPlayContent1min(currentTrackRef?.current);
                    currentTrackRef.current = {
                        ...currentTrackRef?.current,
                        logging: {
                            oneMin: true,
                        },
                    };
                }

                if (totalLengthListened > 300 && !currentTrackRef?.current?.logging?.fiveMin) {
                    logPlayContent5min(currentTrackRef?.current);
                    currentTrackRef.current = {
                        ...currentTrackRef?.current,
                        logging: {
                            ...currentTrackRef.current?.logging,
                            fiveMin: true,
                        },
                    };
                }

                if (totalLengthListened % 60 === 0 && totalLengthListened > 0) {
                    logPlay(currentTrackRef?.current, rmpRef.current.getCurrentTime() / 1000);
                }
            },
            paused({ checkForAd } = {}) {
                pushEvent({ eventType: 'paused', checkForAd });
            },
            ended() {
                pushEvent({ eventType: 'ended' });
            },
            seek(seekFrom, seekTo) {
                logSeek(currentTrackRef?.current, seekFrom, seekTo);
                pushEvent({ eventType: 'seek' });
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
};

export default useEventSink;
