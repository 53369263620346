import React from 'react';

import { Grid as MuiGrid } from '@mui/material';

const Grid = props => {
    const { children, ...rest } = props;
    return <MuiGrid {...rest}>{children}</MuiGrid>;
};

export default Grid;
