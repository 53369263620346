import React, { useMemo } from 'react';

import { lighten, styled } from '@mui/system';
import { getThumbnail, imgType } from '@utils/asset';
import { useIllustration } from '@utils/illustrations';
import { isContentLocked } from '@utils/media';

import Link from '@ui/link/Link';
import Typography from '@ui/Typography';

import MediaCardCover from './mediaCardElements/MediaCardCover';
import PlayButton from './PlayButton';

const Container = styled(Link, {
    shouldForwardProp: prop => prop !== 'img' && prop !== 'extended',
})(({ theme, img }) => ({
    backgroundColor: theme.palette.background.paper,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundImage: img ? `url('${img}')` : 'none',
    borderRadius: theme.shape.borderRadius,
    display: 'block',
    transition: 'background-image 0.2s, background-color 0.2s',
}));

const InnerContainer = styled('div', {
    shouldForwardProp: prop => prop !== 'extended',
})(({ theme, extended }) => ({
    background: extended ? theme.palette.background.appBar : 'transparent',
    backdropFilter: extended ? 'blur(60px) saturate(200%)' : 'none',
    padding: theme.spacing(extended ? 2 : 1),
    borderRadius: theme.shape.borderRadius,

    transition: 'background-image 0.2s, background-color 0.2s',

    '&:hover': {
        backgroundColor: extended
            ? lighten(theme.palette.background.appBar, 0.05)
            : lighten(theme.palette.background.paper, 0.05),
    },
}));

const MainSection = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const Cover = styled('div', {
    shouldForwardProp: prop => prop !== 'extended',
})(({ extended }) => ({
    width: extended ? '100%' : 48,
    height: extended ? '100%' : 48,
    flexGrow: 0,
    flexShrink: 0,
}));

const Meta = styled('div')(({ theme }) => ({
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
}));

const ExtendedTitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    marginTop: theme.spacing(2),
}));

const PlayButtonContainer = styled('div')(({ theme }) => ({
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
}));

const MediaTitle = styled(Typography)({
    overflow: 'hidden',
    transition: 'all 0.2s',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    display: '-webkit-box',
    fontWeight: 'bold',
    marginBottom: 2,
});

const MediaSubtitle = styled(Typography)({
    overflow: 'hidden',
    transition: 'all 0.2s',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    display: '-webkit-box',
});

const MediaDescription = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    overflow: 'hidden',
    transition: 'all 0.2s',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    display: '-webkit-box',
}));

const EpisodeCompactCard = ({ mediaData, noAction, extended }) => {
    const {
        link,
        forcedImage,
        image,
        slug,
        title,
        description,
        parentMedia,
        position: { completeness } = {},
    } = mediaData || {};
    const { podcastImage } = useIllustration();
    const coverImg =
        forcedImage ||
        getThumbnail(image, imgType.thumbnail) ||
        getThumbnail(parentMedia?.image, imgType.thumbnail) ||
        podcastImage?.src;
    const isLockedMemo = useMemo(() => isContentLocked(mediaData), [mediaData]);

    // Newest episodes
    if (extended) {
        return (
            <Container
                href={noAction ? '#' : link || `/${slug}`}
                underline="none"
                color="inherit"
                img={extended ? coverImg : null}
            >
                <InnerContainer extended={extended}>
                    <MainSection>
                        <Cover extended={extended}>
                            <MediaCardCover
                                url={coverImg}
                                alt={title}
                                completeness={completeness}
                                isLocked={isLockedMemo}
                            />
                        </Cover>
                    </MainSection>
                    <ExtendedTitleContainer>
                        {!noAction && (
                            <PlayButtonContainer>
                                <PlayButton mediaData={mediaData} small />
                            </PlayButtonContainer>
                        )}
                        <div>
                            <MediaTitle variant="h5" component="h3" sx={{ mb: '1px' }}>
                                {title}
                            </MediaTitle>
                            {parentMedia?.title && <MediaSubtitle variant="body2">{parentMedia?.title}</MediaSubtitle>}
                        </div>
                    </ExtendedTitleContainer>
                    <MediaDescription variant="body1">{description || parentMedia?.description}</MediaDescription>
                </InnerContainer>
            </Container>
        );
    }

    // Continue listening
    return (
        <Container
            href={noAction ? '#' : link || `/${slug}`}
            underline="none"
            color="inherit"
            img={extended ? coverImg : null}
        >
            <InnerContainer extended={extended}>
                <MainSection>
                    <Cover extended={extended}>
                        <MediaCardCover url={coverImg} alt={title} completeness={completeness} />
                    </Cover>
                    <Meta>
                        <MediaTitle variant="h5" component="h3">
                            {title}
                        </MediaTitle>
                        {parentMedia?.title && <MediaSubtitle variant="body2">{parentMedia?.title}</MediaSubtitle>}
                    </Meta>
                    {!noAction && <PlayButton mediaData={mediaData} small />}
                </MainSection>
            </InnerContainer>
        </Container>
    );
};

export default EpisodeCompactCard;
