import { generateGuid, isGuid } from './guid';
import { getFromLocalStorage, getFromSessionStorage, saveToLocalStorage, saveToSessionStorage } from './storage';
import { clearToken, setToken } from './token';

const sessionStorageKey = 'SESSION_ID';
const deviceStorageKey = 'DEVICE_ID';

export const getSessionId = () => {
    const sessionId = getFromSessionStorage(sessionStorageKey, null);
    if (!sessionId || !isGuid(sessionId)) {
        const newSessionId = generateGuid();
        setSessionId(newSessionId);
        return newSessionId;
    }

    return sessionId;
};

export const getDeviceId = () => {
    const deviceId = getFromLocalStorage(deviceStorageKey, null);
    if (!deviceId || !isGuid(deviceId)) {
        const newDeviceId = generateGuid();
        setDeviceId(newDeviceId);
        return newDeviceId;
    }

    return deviceId;
};

export const setSessionId = id => saveToSessionStorage(sessionStorageKey, id);
export const setDeviceId = id => saveToLocalStorage(deviceStorageKey, id);

export const setSession = token => {
    setToken(token);
    setSessionId(generateGuid());
};

export const clearSession = () => {
    clearToken();
    setSessionId(generateGuid());
};
