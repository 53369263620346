import React, { useMemo } from 'react';

import { styled } from '@mui/system';
import PropTypes from 'prop-types';

import Box from '@ui/Box';
import Dialog from '@ui/Dialog';
import DialogActions from '@ui/DialogActions';
import DialogContent from '@ui/DialogContent';
import DialogTitle from '@ui/DialogTitle';
import Button from '@ui/inputs/Button';
import Typography from '@ui/Typography';

import Cms from '@components/Cms/Cms';
import useCms from '@components/Cms/useCms';
import PodcastProfileLevel from '@components/Podcaster/PodcastProfileLevel';

const FeatureListItem = styled('div')(() => ({}));

const PodcasterLevelDialog = ({ isOpen, onClose }) => {
    const cms = useCms();

    const levels = useMemo(
        () =>
            [1065, 1066, 1067].map((cmsId, index) => (
                <FeatureListItem key={index}>
                    <PodcastProfileLevel level={index + 1} />
                    <Typography sx={{ marginTop: 2, marginBottom: 2 }}>
                        <Cms cmsId={cmsId} html />
                    </Typography>
                </FeatureListItem>
            )),
        []
    );

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} minWidth="670px">
            <DialogTitle>{cms[1064]}</DialogTitle>
            <DialogContent>
                <Box>{levels}</Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                    {cms[1068]}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

PodcasterLevelDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
};

export default PodcasterLevelDialog;
