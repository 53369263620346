/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { firstLastDebounce } from '@utils/debounce';

import useApi from '@components/api/useApi';
import useUser from '@components/User/useUser';

import EventSinkContext from './EventSinkContext';

const errorLimit = 3;

const DEBOUNCE_TIME_MS = 400;

const EventSinkContextProvider = ({ children }) => {
    const api = useApi();
    const { user } = useUser();
    const eventQueue = useRef([]);
    const timerRef = useRef();
    const eventSinkErrors = useRef(0);
    const isUserRef = useRef(false);

    useEffect(() => {
        isUserRef.current = !!user;
    }, [user]);

    const postEvents = useCallback(
        data => {
            if (!process.env.NEXT_PUBLIC_TRACKING_ENABLED) {
                console.log(
                    'reduced',
                    data.map(({ eventType, time }) => `${eventType}${time}`)
                );
                return;
            }

            if (!process.env.NEXT_PUBLIC_EVENT_SINK_URL) {
                console.warn('Event sink url missing');
                return Promise.resolve();
            }

            if (isUserRef.current) {
                return api.post(`/users/positions`, data);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const debouncedAddToQueue = useMemo(
        () =>
            firstLastDebounce(({ firstArgs: [firstEvent], lastArgs: [lastEvent] }) => {
                const data = firstEvent.eventType === lastEvent.eventType ? [firstEvent] : [firstEvent, lastEvent];

                eventQueue.current = [...eventQueue.current, ...data];
            }, DEBOUNCE_TIME_MS),
        []
    );

    const pushEvent = useCallback(
        async event => {
            const { mediaId, eventType, time, playlistId, totalLengthListened } = event;

            const eventQueueItem = {
                happenedAt: new Date().getTime(), // not sent to backend
                mediaId,
                eventType,
                time,
                playlistId,
                totalLengthListened,
            };

            if (eventType === 'started') {
                if (timerRef.current) {
                    clearInterval(timerRef.current);
                }

                eventQueue.current = [...eventQueue.current, { ...eventQueueItem, eventType: 'playing' }];

                timerRef.current = setInterval(() => {
                    flush();
                }, process.env.NEXT_PUBLIC_EVENT_INTERVAL || 10000);

                return;
            }

            if (eventType === 'ended') {
                if (timerRef.current) {
                    clearInterval(timerRef.current);
                }

                if (!eventQueue.current.length) {
                    return;
                }

                if (eventQueue.current[eventQueue.current.length - 1]?.eventType === 'paused') {
                    eventQueue.current[eventQueue.current.length - 1] = { ...eventQueueItem, eventType: 'paused' };
                } else {
                    eventQueue.current = [...eventQueue.current, { ...eventQueueItem, eventType: 'paused' }];
                }

                flush();
                return;
            }

            if (eventType === 'completed') {
                if (timerRef.current) {
                    clearInterval(timerRef.current);
                }

                eventQueue.current = [
                    ...eventQueue.current,
                    { ...eventQueueItem, eventType: 'paused' },
                    eventQueueItem,
                ];
                flush();
                return;
            }

            if (!timerRef.current) {
                return;
            }

            debouncedAddToQueue(eventQueueItem);
        },
        [flush, debouncedAddToQueue]
    );

    const flush = useCallback(async () => {
        if (eventQueue.current.length === 0) {
            return;
        }

        const sortedMappedEvents = eventQueue.current.sort((a, b) => a.happenedAt - b.happenedAt);

        const dataWithCollapsedPlayingEvents = sortedMappedEvents
            .reduce((acc, curr) => {
                const lastIndex = acc.length - 1;
                const indexBeforeLastIndex = acc.length - 2;

                if (
                    (curr.eventType === 'playing' || curr.eventType === 'paused') &&
                    acc?.[lastIndex]?.eventType === 'playing' &&
                    acc?.[indexBeforeLastIndex]?.eventType === 'playing'
                ) {
                    const newAcc = [...acc];
                    newAcc[newAcc.length - 1] = {
                        ...curr,
                        totalLengthListened: newAcc[newAcc.length - 1].totalLengthListened + curr.totalLengthListened,
                    };
                    return newAcc;
                }

                return [...acc, curr];
            }, [])
            .map(({ mediaId, time, totalLengthListened }) => {
                return {
                    mediaId,
                    position: time,
                    totalLengthListened,
                };
            });

        try {
            await postEvents(dataWithCollapsedPlayingEvents);
            eventQueue.current = [];
        } catch (error) {
            if (eventSinkErrors.current > errorLimit) {
                eventSinkErrors.current = 0;
                eventQueue.current = [];
                throw error;
            }

            eventSinkErrors.current += 1;
        }
    }, [postEvents]);

    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, []);

    return <EventSinkContext.Provider value={{ pushEvent }}>{children}</EventSinkContext.Provider>;
};

export default EventSinkContextProvider;
