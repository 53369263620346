import React, { useMemo } from 'react';

import { styled } from '@mui/system';
import { isContentLocked } from '@utils/media';

import { PauseRounded, PlayArrowRounded } from '@mui/icons-material';

import IconButton from '@ui/IconButton';

import useCms from '@components/Cms/useCms';
import { useLockedContentPopup } from '@components/LockedContent/LockedContentContextProvider';
import useMatureContext from '@components/MatureContext/useMatureContext';
import useRadiantPlayerContext from '@components/RadiantPlayer/useRadiantPlayer';
import useUser from '@components/User/useUser';

const PlayIconButton = styled(IconButton)(({ theme }) => ({
    background: theme.palette.primary.main,
    background: 'linear-gradient(315deg, #E94713 0%, #FD8A3E 100%)',
    transition: 'background 0.2s',
    color: '#FFFFFF',
    pointerEvents: 'auto',
    ':hover': {
        background: theme.palette.primary.dark,
        background: 'linear-gradient(315deg, #E94713 0%, #E94713 100%)',
    },
    flexShrink: 0,
}));

const PlayButton = ({ disabled, mediaData, forceEnabled, mediaStartTime, listId, small, onStartPlaying }) => {
    const cms = useCms();
    const { isMature: isUserMature } = useUser();
    const { openMatureContentPopup } = useMatureContext();
    const { isPlaying, currentTrack, playTrack, togglePlayPause } = useRadiantPlayerContext();
    const { openLockedPopup } = useLockedContentPopup();
    const isLockedMemo = useMemo(() => isContentLocked(mediaData), [mediaData]);

    const isPublished = mediaData?.state === 'published';
    const mediaId = mediaData?.id;
    const isCurrentTrackPlaying = currentTrack?.id === mediaId && isPlaying;
    const isMatureContent = mediaData?.isMatureContent;

    const size = small ? 20 : 32;

    const isDisabled = disabled || (!forceEnabled && !isPublished) || (isMatureContent && isUserMature === false);

    const handler = () => {
        if (currentTrack && mediaData.id === currentTrack.id) {
            togglePlayPause();
            return;
        }

        playTrack(mediaData, mediaStartTime, listId);

        onStartPlaying?.();
    };

    const handleButtonClick = e => {
        e.preventDefault();
        if (isLockedMemo) {
            openLockedPopup();
            return;
        }

        if (isMatureContent && isUserMature === null) {
            openMatureContentPopup(handler);
            return;
        }

        if (!isMatureContent || isUserMature) {
            handler();
        }
    };

    return (
        <PlayIconButton
            aria-label={`${cms[635]} – ${mediaData?.title}`}
            disabled={isDisabled}
            onClick={handleButtonClick}
        >
            {isCurrentTrackPlaying ? (
                <PauseRounded sx={{ height: size, width: size }} />
            ) : (
                <PlayArrowRounded sx={{ height: size, width: size }} />
            )}
        </PlayIconButton>
    );
};

export default PlayButton;
