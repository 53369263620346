import React from 'react';

import PauseCircleOutlineRoundedIcon from '@mui/icons-material/PauseCircleOutlineRounded';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import { styled } from '@mui/system';

import Box from '@ui/Box';
import Checkbox from '@ui/inputs/Checkbox';
import Tooltip from '@ui/Tooltip';

import useCms from '@components/Cms/useCms';

import useRadiantPlayerContext from '../useRadiantPlayer';

const Container = styled(Box)({
    flexGrow: 0,
    flexShrink: 0,
});

const PlayNextSwitch = () => {
    const { autoPlayNext, setAutoPlayNext } = useRadiantPlayerContext();
    const cms = useCms();

    const handleChange = event => {
        setAutoPlayNext(event?.target?.checked);
    };

    return (
        <Tooltip title={cms[2019]} arrow>
            <Container>
                <Checkbox
                    checked={autoPlayNext}
                    onChange={handleChange}
                    color="secondary"
                    icon={<PauseCircleOutlineRoundedIcon />}
                    checkedIcon={<PlayCircleOutlineRoundedIcon />}
                />
            </Container>
        </Tooltip>
    );
};

export default PlayNextSwitch;
