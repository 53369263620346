const getFromStorage = (storage, key, defaultValue) => {
    try {
        const raw = global?.window?.[storage]?.getItem?.(key);
        return raw ? JSON.parse(raw) : defaultValue !== undefined ? defaultValue : {};
    } catch (e) {
        console.error(e);
        return defaultValue !== undefined ? defaultValue : {};
    }
};

const saveToStorage = (storage, key, data) => {
    if (!global.window) {
        return;
    }

    try {
        const raw = JSON.stringify(data);
        window[storage].setItem(key, raw);
    } catch (e) {
        console.error(e);
    }
};

const removeFromStorage = (storage, key) => {
    if (!global.window) {
        return;
    }

    try {
        window[storage].removeItem(key);
    } catch (e) {
        console.error(e);
    }
};

export const clearStorage = storage => {
    if (!global.window) {
        return;
    }

    try {
        window[storage].clear();
    } catch (e) {
        console.error(e);
    }
};

export const getFromLocalStorage = (key, defaultValue) => getFromStorage('localStorage', key, defaultValue);
export const saveToLocalStorage = (key, data) => saveToStorage('localStorage', key, data);
export const removeFromLocalStorage = key => removeFromStorage('localStorage', key);
export const clearLocalStorage = () => clearStorage('localStorage');

export const getFromSessionStorage = (key, defaultValue) => getFromStorage('sessionStorage', key, defaultValue);
export const saveToSessionStorage = (key, data) => saveToStorage('sessionStorage', key, data);
export const removeFromSessionStorage = key => removeFromStorage('sessionStorage', key);
export const clearSessionStorage = () => clearStorage('sessionStorage');
