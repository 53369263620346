import React from 'react';

import { useIllustration } from '@utils/illustrations';
import Image from 'next/image';
import PropTypes from 'prop-types';

import Box from '@ui/Box';
import Dialog from '@ui/Dialog';
import DialogContent from '@ui/DialogContent';
import DialogTitle from '@ui/DialogTitle';
import Button from '@ui/inputs/Button';
import Typography from '@ui/Typography';

import useCms from '@components/Cms/useCms';

const EnableAutoPlayDialog = ({ isOpen, onClose, onEnable, small }) => {
    const cms = useCms();
    const { audio } = useIllustration();

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{cms[601]}</DialogTitle>
            <DialogContent>
                <Box textAlign="center">
                    <Image src={audio.src} width={small ? 50 : 200} height={small ? 50 : 200} alt="" />
                    <Typography>{cms[602]}</Typography>
                    <Box textAlign="center" sx={{ mt: 4 }}>
                        <Button variant="outlined" onClick={onClose} sx={{ mr: 2 }}>
                            {cms[603]}
                        </Button>
                        <Button color="primary" onClick={onEnable}>
                            {cms[604]}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

EnableAutoPlayDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onEnable: PropTypes.func,
    small: PropTypes.bool,
};

export default EnableAutoPlayDialog;
