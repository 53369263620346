import alertDark from '@images/illustrations/alert-dark.svg';
import alertLight from '@images/illustrations/alert-light.svg';
import audioDark from '@images/illustrations/audio-dark.svg';
import audioLight from '@images/illustrations/audio-light.svg';
import brokenLinkDark from '@images/illustrations/broken-link-dark.svg';
import brokenLinkLight from '@images/illustrations/broken-link-light.svg';
import checkboxImageDark from '@images/illustrations/checkbox-image-dark.svg';
import checkboxImageLight from '@images/illustrations/checkbox-image-light.svg';
import emptyFavoriteDark from '@images/illustrations/empty-favorite-dark.svg';
import emptyFavoriteLight from '@images/illustrations/empty-favorite-light.svg';
import emptyMyListDark from '@images/illustrations/empty-mylist-dark.svg';
import emptyMyListLight from '@images/illustrations/empty-mylist-light.svg';
import emptySearchDark from '@images/illustrations/empty-search-dark.svg';
import emptySearchLight from '@images/illustrations/empty-search-light.svg';
import exclusivePodcastDark from '@images/illustrations/exclusive-podcast-dark.svg';
import exclusivePodcastLight from '@images/illustrations/exclusive-podcast-light.svg';
import podcastProfileDark from '@images/illustrations/podcast-profile-dark.svg';
import podcastProfileLight from '@images/illustrations/podcast-profile-light.svg';
import podcastStarFilled from '@images/illustrations/podcast-star-filled.svg';
import podcastStarUnFilled from '@images/illustrations/podcast-star-unfilled.svg';
import podcasterLevelsInfo from '@images/illustrations/podcaster-levels-info.svg';
import unlockFeaturesDark from '@images/illustrations/unlock-features-dark.svg';
import unlockFeaturesLight from '@images/illustrations/unlock-features-light.svg';
import categoryDark from '@images/placeholders/category-dark.svg';
import categoryLight from '@images/placeholders/category-light.svg';
import hostDark from '@images/placeholders/host-dark.svg';
import hostLight from '@images/placeholders/host-light.svg';
import loadMoreDark from '@images/placeholders/load-more-dark.svg';
import loadMoreLight from '@images/placeholders/load-more-light.svg';
import podcastDark from '@images/placeholders/podcast-dark.svg';
import podcastLight from '@images/placeholders/podcast-light.svg';
import radioDark from '@images/placeholders/radio-dark.svg';
import radioLight from '@images/placeholders/radio-light.svg';
import unpublishedDark from '@images/placeholders/unpublished-dark.svg';
import unpublishedLight from '@images/placeholders/unpublished-light.svg';
import { useTheme } from '@mui/system';

const illustrations = {
    light: {
        audio: audioLight,
        alert: alertLight,
        brokenLink: brokenLinkLight,
        emptyFavorite: emptyFavoriteLight,
        emptyMyList: emptyMyListLight,
        emptySearch: emptySearchLight,
        unlockFeatures: unlockFeaturesLight,
        loadMoreImage: loadMoreLight,
        podcastImage: podcastLight,
        categoryImage: categoryLight,
        hostImage: hostLight,
        radioImage: radioLight,
        unpublishedImage: unpublishedLight,
        exclusivePodcast: exclusivePodcastLight,
        podcastProfile: podcastProfileLight,
        checkboxImage: checkboxImageLight,
        podcastStarFilled: podcastStarFilled,
        podcastStarUnFilled: podcastStarUnFilled,
        podcasterLevelsInfo: podcasterLevelsInfo,
    },
    dark: {
        audio: audioDark,
        alert: alertDark,
        brokenLink: brokenLinkDark,
        emptyFavorite: emptyFavoriteDark,
        emptyMyList: emptyMyListDark,
        emptySearch: emptySearchDark,
        unlockFeatures: unlockFeaturesDark,
        loadMoreImage: loadMoreDark,
        podcastImage: podcastDark,
        categoryImage: categoryDark,
        hostImage: hostDark,
        radioImage: radioDark,
        unpublishedImage: unpublishedDark,
        exclusivePodcast: exclusivePodcastDark,
        podcastProfile: podcastProfileDark,
        checkboxImage: checkboxImageDark,
        podcastStarFilled: podcastStarFilled,
        podcastStarUnFilled: podcastStarUnFilled,
        podcasterLevelsInfo: podcasterLevelsInfo,
    },
};

export const useIllustration = () => {
    const theme = useTheme();
    return illustrations[theme.palette.mode];
};
