import React from 'react';

import { ListItem as MuiListItem } from '@mui/material';

const ListItem = props => {
    const { children, ...rest } = props;
    return <MuiListItem {...rest}>{children}</MuiListItem>;
};

export default ListItem;
