/* eslint-disable max-lines */
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import darkTheme from './darkTheme';
import lightTheme from './lightTheme';

const shape = {
    borderRadius: 10,
};

const typography = {
    fontSize: 14,
    fontFamily: '"Ubuntu", sans-serif',
    h1: {
        fontWeight: 700,
        fontSize: '3rem',
        lineHeight: 1.1,
        marginLeft: -2,
    },
    h2: {
        fontWeight: 400,
        fontSize: '1.2rem',
    },
    h3: {
        fontWeight: 400,
        fontSize: '1rem',
    },
    h4: {
        fontWeight: 400,
        fontSize: '1rem',
        textTransform: 'uppercase',
        letterSpacing: 0.5,
    },
    h5: {
        fontSize: 14,
        lineHeight: 1.5,
    },
    body1: {
        fontSize: 14,
        lineHeight: 1.5,
    },
    body2: {
        fontWeight: 400,
    },
};

const getComponents = palette => ({
    MuiCssBaseline: {
        styleOverrides: `
            .grecaptcha-badge {
                visibility: hidden;
            }
            .recaptcha-info a {
                color: inherit;
            }
            .cms-link a {
                color: ${palette.primary.main}
            }
        `,
    },
    MuiTextField: {
        defaultProps: {
            variant: 'filled',
            InputLabelProps: {
                shrink: true,
            },
        },
    },
    MuiFilledInput: {
        styleOverrides: {
            root: {
                borderTopLeftRadius: shape.borderRadius,
                borderTopRightRadius: shape.borderRadius,
                borderBottomLeftRadius: shape.borderRadius,
                borderBottomRightRadius: shape.borderRadius,
                backgroundColor: palette.input.background,
                border: `1px solid ${palette.input.border}`,
                transition: 'all 0.2s',
                '&:hover': {
                    backgroundColor: palette.input.hoverBackground,
                    border: `1px solid ${palette.input.hoverBorder}`,
                },
                '&.Mui-focused': {
                    backgroundColor: palette.input.focusedBackground,
                    border: `1px solid ${palette.input.focusedBorder}`,
                },
            },
        },
        defaultProps: {
            disableUnderline: true,
        },
    },
    MuiButton: {
        styleOverrides: {
            containedPrimary: {
                background: palette.primary.main,
                background: palette.primary.gradient,
                '&:hover': {
                    background: palette.primary.gradientHover,
                },
            },
            sizeLarge: {
                height: 'calc(1.3em + 33px)',
            },
        },
    },
    MuiListSubheader: {
        styleOverrides: {
            root: {
                backgroundColor: 'inherit',
                fontSize: '0.7rem',
                textTransform: 'uppercase',
                letterSpacing: '2px',
                fontWeight: 400
            },
        },
    },
    MuiDialog: {
        styleOverrides: {
            paper: {
                backgroundColor: palette.background.default,
                backgroundImage: 'none',
            },
        },
    },
    MuiSlider: {
        styleOverrides: {
            track: {
                background: palette.primary.gradient,
                transition: 'none',
            },
            rail: {
                background: palette.background.default,
                opacity: 1,
                border: `1px solid ${palette.divider}`,
            },
            thumb: {
                width: 16,
                height: 16,
                ':before': {
                    boxShadow: 'none',
                },
                transition: 'none',
            },
            valueLabel: {
                background: palette.background.default,
                color: palette.text.secondary,
                fontWeight: 400,
            },
        },
    },
    MuiAccordion: {
        styleOverrides: {
            root: {
                backgroundImage: 'none',
            },
        },
    },
});

const dark = createTheme({
    palette: darkTheme.palette,
    typography: {
        ...typography,
        h2: {
            ...typography.h2,
            color: 'rgba(217,230,237,0.5)',
        },
        h4: {
            ...typography.h4,
            color: 'rgba(217,230,237,0.5)',
        },
        body2: {
            ...typography.body2,
            color: 'rgba(217,230,237,0.5)',
        },
    },
    components: getComponents(darkTheme.palette),
    shape,
});

const light = createTheme({
    palette: lightTheme.palette,
    typography: {
        ...typography,
        body2: {
            ...typography.body2,
            color: lightTheme.palette.text.secondary,
        },
    },
    components: getComponents(lightTheme.palette),
    shape,
});

const themes = {
    dark: responsiveFontSizes(dark),
    light: responsiveFontSizes(light),
};

export default themes;
