import { toast } from 'react-toastify';

const getApiError = (error, defaultError) => {
    return error?.error || defaultError;
};

export const toastApiError = (error, defaultError) => {
    console.error(error);
    toast.error(getApiError(error, defaultError));
};

export const toastError = message => {
    toast.error(message);
};

export const toastWarning = message => {
    toast.warning(message);
};

export const toastSuccess = message => {
    toast.success(message);
};
