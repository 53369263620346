import React, { useEffect, useMemo, useState } from 'react';

import { debounce } from '@utils/debounce';
import { useRouter } from 'next/router';
import * as yup from 'yup';

import { SearchRounded as SearchIcon } from '@mui/icons-material';

import FormAutoComplete from '@ui/form/FormAutoComplete';
import SearchContainer from '@ui/SearchContainer';

import useCms from '@components/Cms/useCms';
import Form from '@components/Form/Form';

import useSearch from './hooks/useSearch';

const searchDelay = 100;

const Search = ({ onSearch }) => {
    const cms = useCms();
    const router = useRouter();
    const [options, loadingSuggest, suggest, resetSearch] = useSearch();

    const [open, setOpen] = useState(false);

    const schema = useMemo(
        () =>
            yup.object().shape({
                search: yup.string().nullable(),
            }),
        []
    );

    const handleChange = useMemo(
        () =>
            debounce(async (_, value, reason) => {
                if (!value) {
                    onSearch('');
                    return;
                }

                if (reason === 'reset') {
                    onSearch(value);
                    return;
                }

                suggest(value, 'suggest');
            }, searchDelay),
        [onSearch, suggest]
    );

    useEffect(() => {
        if (!open) {
            resetSearch();
        }
    }, [open, resetSearch]);

    useEffect(() => {
        const handleRouteChange = () => {
            onSearch('');
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SearchContainer>
            <Form
                onSubmit={({ search }) => onSearch(search)}
                defaultValues={{ search: '' }}
                schema={schema}
                style={{ width: '100%' }}
            >
                <FormAutoComplete
                    fullWidth
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    id="search"
                    name="search"
                    placeholder={cms[186]}
                    disabled={false}
                    options={options}
                    freeSolo
                    loading={loadingSuggest}
                    onInputChange={handleChange}
                    startAdornment={<SearchIcon />}
                    sx={{ mb: 0 }}
                />
            </Form>
        </SearchContainer>
    );
};

export default Search;
