/* eslint-disable max-lines */
import React from 'react';

import { TableRow as MuiTableRow, TableCell } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';

import { Close, Delete, Edit, ImportExport, Save } from '@mui/icons-material';

import IconButton from '@ui/IconButton';
import Checkbox from '@ui/inputs/Checkbox';
import Tooltip from '@ui/Tooltip';

import useCms from '@components/Cms/useCms';

import tablePropTypes from './Table.propTypes';

const TableCellStyled = styled(TableCell)(({ theme }) => ({
    minWidth: '75px',
    color: theme.palette.text.secondary,
}));

const tableRowPropNames = ['ordering', 'clickable', 'isOrderTarget'];
const TableRowStyled = styled(MuiTableRow, {
    shouldForwardProp: prop => !tableRowPropNames.includes(prop),
})(({ theme, ordering, clickable, isOrderTarget }) => ({
    cursor: clickable ? 'pointer' : 'default',
    opacity: isOrderTarget ? 1 : ordering ? 0.5 : 1,
    '&:hover $controlCell': {
        opacity: 1,
    },
    '&:hover': {
        borderBottom: ordering ? `2px solid ${theme.palette.primary.main}` : 'none',
    },
}));

const TableRow = React.forwardRef(
    (
        {
            children,
            onOrder,
            clickable,
            isOrderTarget,
            editable,
            selectable,
            selected,
            editing,
            onEdit,
            onEditCancel,
            onSave,
            onDelete,
            ordering,
            orderable,
            onSelect,
            size,
            ...props
        },
        ref
    ) => {
        const cms = useCms();

        return (
            <TableRowStyled
                ref={ref}
                clickable={clickable}
                isOrderTarget={isOrderTarget}
                ordering={ordering}
                {...props}
            >
                {selectable && (
                    <TableCell padding="checkbox">
                        <Checkbox size={size} checked={selected} onChange={onSelect} />
                    </TableCell>
                )}
                {orderable && (
                    <TableCellStyled>
                        {!ordering && (
                            <Tooltip title={cms[263]}>
                                <IconButton onClick={onOrder}>
                                    <ImportExport />
                                </IconButton>
                            </Tooltip>
                        )}
                    </TableCellStyled>
                )}
                {children}
                {editable && (
                    <TableCellStyled>
                        {editing ? (
                            <>
                                <Tooltip title={cms[433]}>
                                    <IconButton onClick={onEditCancel}>
                                        <Close />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={cms[440]}>
                                    <IconButton onClick={onDelete}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={cms[434]}>
                                    <IconButton onClick={onSave}>
                                        <Save />
                                    </IconButton>
                                </Tooltip>
                            </>
                        ) : (
                            <Tooltip title={cms[432]}>
                                <IconButton onClick={onEdit}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        )}
                    </TableCellStyled>
                )}
            </TableRowStyled>
        );
    }
);

TableRow.propTypes = {
    orderable: PropTypes.bool,
    clickable: PropTypes.bool,
    ordering: PropTypes.bool,
    isOrderTarget: PropTypes.bool,
    onOrder: PropTypes.func,
    editable: PropTypes.bool,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
    editing: PropTypes.bool,
    onEdit: PropTypes.func,
    onEditCancel: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onSelect: PropTypes.func,
    size: tablePropTypes.size,
};

export default React.memo(TableRow);
