import React, { useEffect, useState } from 'react';

import { styled } from '@mui/system';
import { formatDuration } from '@utils/date';

import Box from '@ui/Box';
import Slider from '@ui/inputs/Slider';
import Typography from '@ui/Typography';

import useRadiantPlayerContext from '../useRadiantPlayer';

const Container = styled(Box)(({ theme }) => ({
    width: '100%',
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    marginRight: theme.spacing(1),
    '& *': {
        userSelect: 'none',
    },
}));

const ElapsedTime = styled(Typography)(({ theme }) => ({
    cursor: 'default',
    marginRight: theme.spacing(2),
}));

const RemainingTime = styled(Typography)(({ theme }) => ({
    display: 'none',
    cursor: 'default',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
        display: 'block',
    },
}));

const SeekBar = () => {
    const { currentTrack, pause, playingAtSeconds, seekTo } = useRadiantPlayerContext();
    const [currentPosition, setCurrentPosition] = useState(playingAtSeconds);

    useEffect(() => {
        setCurrentPosition(playingAtSeconds);
    }, [playingAtSeconds]);

    const handleSeeking = value => {
        pause();
        setCurrentPosition(value);
    };

    const handleSeekChange = value => {
        seekTo(value);
    };

    const durationSeconds =
        (currentTrack?.duration ? currentTrack?.duration?.seconds : currentTrack?.assets?.[0]?.duration?.seconds) || 0;

    return (
        <Container>
            <ElapsedTime variant="body2">{formatDuration(playingAtSeconds, true)}</ElapsedTime>
            <Slider
                value={currentPosition}
                min={0}
                max={durationSeconds}
                onChange={(_, value) => handleSeeking(value)}
                onChangeCommitted={(_, value) => handleSeekChange(value)}
                valueLabelDisplay="auto"
                valueLabelFormat={formatDuration}
            />
            <RemainingTime variant="body2">{formatDuration(durationSeconds - playingAtSeconds, true)}</RemainingTime>
        </Container>
    );
};

export default SeekBar;
