import { alpha, styled } from '@mui/system';

const SearchContainer = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.up('md')]: {
        marginLeft: 0,
        width: '40%',
    },
    '& .MuiFilledInput-root': {
        paddingTop: '0 !important',
    },
}));

export default SearchContainer;
