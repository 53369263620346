import React, { useEffect, useMemo, useState } from 'react';

import * as PropTypes from 'prop-types';

import useCms from './useCms';

const formatText = (text, args) => {
    if (!args || !Array.isArray(args)) {
        return text;
    }

    let formattedText = text;
    args.forEach((t, i) => {
        if (t != null && typeof t !== 'object') {
            formattedText = formattedText.replace(`{${i}}`, t.toString());
        }
    });

    return formattedText;
};

const fragmentText = (text, args, hasFragments) => {
    if (!hasFragments || !args || !Array.isArray(args)) {
        return text;
    }

    const regex = /{\d*}/gim;

    const splitText = text
        .replace(regex, match => {
            return `${match}${/[\d*]/gim.exec(match)[0]}${match}`;
        })
        .split(regex);

    return (
        <>
            {splitText.reduce((acc, t, i) => {
                if (t !== '' && t !== null) {
                    const index = parseInt(t);
                    acc.push(<React.Fragment key={t + i}>{isNaN(index) ? t : args[index]}</React.Fragment>);
                }

                return acc;
            }, [])}
        </>
    );
};

const getHtml = (text, { tag, className }) => {
    if (tag === 'span') {
        return <span dangerouslySetInnerHTML={{ __html: text }} className={className} />;
    }

    return <div dangerouslySetInnerHTML={{ __html: text }} className={className} />;
};

const Cms = ({ cmsId, args, html, tag, defaultText, className }) => {
    const cms = useCms();

    const [hasFragments, setHasFragments] = useState(!!args?.filter(a => typeof a === 'object')?.length);

    useEffect(() => {
        setHasFragments(!!args?.filter(a => typeof a === 'object')?.length);
    }, [args]);

    const cmsText = formatText(cms[cmsId] || defaultText, args || []);
    const memFragmentText = useMemo(() => fragmentText(cmsText, args, hasFragments), [cmsText, args, hasFragments]);

    if (isNaN(cmsId)) {
        return `Invalid cms id: ${cmsId}`;
    }

    return html ? getHtml(cmsText, { tag, className }) : hasFragments ? memFragmentText : cmsText;
};

Cms.propTypes = {
    cmsId: PropTypes.number.isRequired,
    args: PropTypes.array,
    html: PropTypes.bool,
    tag: PropTypes.oneOf(['span', 'div']),
    defaultText: PropTypes.string,
    core: PropTypes.bool,
};

Cms.defaultProps = {
    tag: 'span',
    args: [],
    html: false,
    defaultText: '',
    core: false,
};

export default Cms;
