export const searchPath = 'https://podcast.search.windows.net/indexes/media/docs/search?api-version=2020-06-30';

export const suggestPath = 'https://podcast.search.windows.net/indexes/media/docs/suggest?api-version=2020-06-30';

export const baseSearchBody = {
    queryType: 'simple',
    searchFields: 'Title, Description',
    select: 'Id, Slug,  Hosts/Title, Title, Type, Image',
    facets: ['Type'],
    count: true,
    top: 30,
    skip: 0,
};

export const baseSuggestBody = {
    fuzzy: true,
    searchFields: 'Title',
    select: 'Slug, Title, IsMatureContent',
    suggesterName: 'sg',
    top: 5,
};
