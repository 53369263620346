export const readFileAsync = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = e => {
            const result = e.target.result;
            resolve(result);
        };

        reader.onerror = e => {
            reject(e);
        };

        reader.readAsDataURL(file);
    });

export const blobToBase64Async = blob =>
    new Promise(resolve => {
        let reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };

        reader.readAsDataURL(blob);
    });

export const imageSize = file => {
    const img = document.createElement('img');
    const url = window?.URL?.createObjectURL(file);

    const promise = new Promise((resolve, reject) => {
        img.onload = () => {
            // Natural size is the actual image size regardless of rendering.
            // The 'normal' `width`/`height` are for the **rendered** size.
            const width = img.naturalWidth;
            const height = img.naturalHeight;

            // Resolve promise with the width and height
            resolve({ width, height });
        };

        // Reject promise on error
        img.onerror = reject;
    });

    // Setting the source makes it start downloading and eventually call `onload`
    img.src = url;

    return promise;
};

export const getFileMeta = file => {
    if (!file) {
        return null;
    }

    const chunkSize = 500 * 1024;
    const length = file.size;
    const mimeType = file.type;
    const name = file.name || '';
    const totalParts = Math.ceil(length / chunkSize);

    return {
        length,
        totalParts,
        chunkSize,
        mimeType,
        name,
    };
};

export const imageLoader = ({ src, width, quality }) => {
    if (!src.includes(process.env.NEXT_PUBLIC_ASSETS_CDN)) {
        return `${src}?w=${width}&q=${quality || 75}`;
    }

    const map = {
        1536: '@2x',
        1200: '@2x',
        900: '@2x',
        600: '',
    };

    return `${src + map[width]}?w=${width}`;
};

export const imgType = {
    thumbnail: 'thumb',
    optimal: 'opt',
};

export const getThumbnail = (url, size = imgType.optimal) => {
    if (!url) {
        return null;
    }

    if (url.includes('podcastportal-image.azureedge.net') || url.includes('podcastportal-image-2024.azureedge.net')) {
        return url;
    }

    const imgSize = {
        [imgType.thumbnail]: `${url}-thumb`,
        [imgType.optimal]: `${url}-opt`,
    };

    return imgSize[size];
};
