import React from 'react';

import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import PropTypes from 'prop-types';

import Box from './Box';
import Typography from './Typography';

const Empty = ({ emptyText, action, p }) => {
    return (
        <Box width="100%" textAlign="center" p={p}>
            <CenterFocusWeakIcon sx={{ color: 'text.secondary', fontSize: 40 }} />
            <Typography mb={action ? 1 : 0} color="textSecondary">
                {emptyText}
            </Typography>
            {action || null}
        </Box>
    );
};

Empty.defaultProps = {
    p: 4,
};

Empty.propTypes = {
    emptyText: PropTypes.string.isRequired,
    action: PropTypes.element,
    p: PropTypes.number,
};

export default Empty;
