const palette = {
    mode: 'dark',
    primary: {
        main: '#F06734',
        dark: '#E94713',
        gradient: 'linear-gradient(315deg, #E94713 0%, #FD8A3E 100%)',
        gradientReversed: 'linear-gradient(0deg, #E94713 0%, #FD8A3E  100%)',
        gradientHover: 'linear-gradient(315deg, #E94713 0%, #E94713 100%)',
        contrastText: '#FFFFFF',
    },
    secondary: {
        main: '#C1D2DB',
    },
    background: {
        default: '#151a22',
        paper: '#1D242D',
        appBar: '#151a22BB',
        light: '#40505d',
        backdrop: '#151a22F0',
        warning: 'rgba(230, 187, 79, 0.1)',
    },
    text: {
        primary: '#C1D2DB',
        secondary: 'rgba(217,230,237,0.5)',
        success: '#6CCC54',
        warning: '#E5BB4F',
    },
    divider: '#273139',
    dividerLight: '#1b2126',
    input: {
        background: '#1D242DAA',
        border: '#273139',
        hoverBackground: '#1D242DAA',
        hoverBorder: '#40505d',
        focusedBackground: '#1D242D',
        focusedBorder: '#40505d',
    },
    action: {
        hover: '#273139',
    },
    chart: [
        {
            backgroundColor: '#f06734a1',
            hoverBackgroundColor: '#F06734',
            borderColor: '#F06734',
            borderWidth: 1,
            borderRadius: 4,
        },
        {
            backgroundColor: 'rgba(217,230,237,0.1)',
            hoverBackgroundColor: 'rgba(217,230,237,0.3)',
            borderColor: 'rgba(217,230,237,0.3)',
            borderWidth: 1,
            borderRadius: 4,
        },
    ],
};

const darkTheme = {
    palette,
};

export default darkTheme;
