import React from 'react';

import { Tooltip as MuiTooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/system';

const Tooltip = styled(({ className, ...props }) => (
    <MuiTooltip {...props} arrow placement="top" classes={{ popper: className }} />
))(({ theme }) => ({
    [`&.MuiTooltip-popper`]: {
        zIndex: 7000,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.background.paper,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        boxShadow: theme.shadows[5],
        textAlign: 'center',
    },
}));

export default Tooltip;
