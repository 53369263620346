import React from 'react';

import { SkipNextRounded } from '@mui/icons-material';

import Button from '@ui/inputs/Button';

import useCms from '@components/Cms/useCms';
import useRadiantPlayerContext from '@components/RadiantPlayer/useRadiantPlayer';

const AdInfo = ({ sx, ...rest }) => {
    const { isAdSkippable, handleSkipAd, playingAtSeconds, adSkipTimeOffset, adDuration } = useRadiantPlayerContext();

    const cms = useCms();
    const isSkipable = isAdSkippable || adSkipTimeOffset === -1;
    const durationLabel = isSkipable ? (
        <SkipNextRounded sx={{ ml: 1, fontSize: 13 }} />
    ) : (
        ` (${adSkipTimeOffset - playingAtSeconds})`
    );

    const remainingTimeLabel = ` (${adDuration - playingAtSeconds})`;

    return (
        <Button
            color="secondary"
            disabled={!isAdSkippable}
            sx={{ mr: 1, px: 1, py: '3px', fontSize: '11px', ...sx }}
            size="small"
            onClick={handleSkipAd}
            {...rest}
        >
            {adSkipTimeOffset && isAdSkippable > 0 ? cms[636] : cms[637]}
            {adSkipTimeOffset > 0 ? durationLabel : remainingTimeLabel}
        </Button>
    );
};

export default AdInfo;
