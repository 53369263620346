import { styled } from '@mui/system';

import IconButton from './IconButton';

const FilledIconButton = styled(IconButton)(({ theme }) => ({
    background: theme.palette.primary.main,
    background: 'linear-gradient(315deg, #E94713 0%, #FD8A3E 100%)',
    transition: 'background 0.2s',
    color: '#FFFFFF',
    pointerEvents: 'auto',
    ':hover': {
        background: theme.palette.primary.dark,
        background: 'linear-gradient(315deg, #E94713 0%, #E94713 100%)',
    },
    flexShrink: 0,
    flexGrow: 0,
    width: 40,
    height: 40,
    marginRight: theme.spacing(2),
    position: 'relative',
}));

export default FilledIconButton;
