import React from 'react';

import { Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import { useIllustration } from '@utils/illustrations';
import Image from 'next/image';

import Paper from '@ui/Paper';

const Container = styled(Paper)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    width: 145,
}));

const illustrationSize = {
    width: 25,
    height: 25,
};

const PodcastProfileLevel = ({ level = 1, sx, loading }) => {
    const { podcastStarFilled, podcastStarUnFilled } = useIllustration();
    const levels = Array.from(Array(3).keys());

    if (loading) {
        return <Skeleton variant="rectangle" width={145} height={57} sx={{ borderRadius: 1 }} />;
    }

    return (
        <Container sx={sx}>
            {levels.map(i => (
                <Image key={i} src={i < level ? podcastStarFilled : podcastStarUnFilled} {...illustrationSize} alt="" />
            ))}
        </Container>
    );
};

export default PodcastProfileLevel;
