import React from 'react';

import Box from '@ui/Box';

import useRadiantPlayerContext from '@components/RadiantPlayer/useRadiantPlayer';

const SidebarPlayerPlaceholder = () => {
    const { currentTrack } = useRadiantPlayerContext();

    return currentTrack ? <Box sx={{ height: 61 }} /> : null;
};

export default SidebarPlayerPlaceholder;
