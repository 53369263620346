import React from 'react';

import { List as MuiList } from '@mui/material';

const List = props => {
    const { children, ...rest } = props;
    return <MuiList {...rest}>{children}</MuiList>;
};

export default List;
