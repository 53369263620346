import React from 'react';

import { FormHelperText, Checkbox as MuiCheckbox } from '@mui/material';
import PropTypes from 'prop-types';

import FormControlLabel from '@ui/form/FormControlLabel';

const Checkbox = ({ children, label, error, ...props }) => {
    if (label) {
        return (
            <>
                <FormControlLabel control={<MuiCheckbox {...props}>{children}</MuiCheckbox>} label={label} />
                {error && <FormHelperText error>{error.message}</FormHelperText>}
            </>
        );
    }

    return <MuiCheckbox {...props}>{children}</MuiCheckbox>;
};

Checkbox.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Checkbox;
