import React from 'react';

import { useIsDesktop } from 'utils/hooks';

import Box from '@ui/Box';
import Drawer from '@ui/Drawer';

const Sidebar = ({ drawerWidth, mobileOpen, handleDrawerToggle, children: drawer }) => {
    const isDesktop = useIsDesktop();
    return (
        <Box sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
            {!isDesktop ? (
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundImage: 'none' },
                    }}
                >
                    {drawer}
                </Drawer>
            ) : (
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            )}
        </Box>
    );
};

export default Sidebar;
