import React from 'react';

import { styled } from '@mui/system';
import PropTypes from 'prop-types';

import ListItemButtonLink from '@ui/link/ListItemButtonLink';
import ListItem from '@ui/ListItem';
import ListItemButton from '@ui/ListItemButton';
import ListItemIcon from '@ui/ListItemIcon';
import ListItemText from '@ui/ListItemText';

const coloredStyles = ({ theme, active }) => ({
    color: active ? theme.palette.primary.main : theme.palette.text.primary,
    minWidth: '36px'
});
const styleOptions = {
    shouldForwardProp: prop => prop !== 'active',
};

const ColoredListItem = styled(ListItem, styleOptions)(coloredStyles);

const ColoredListItemIcon = styled(ListItemIcon, styleOptions)(coloredStyles);

const StyledListItemText = styled(ListItemText)({
    '& span': {
        fontWeight: 700
    }
});

const SidebarListItem = ({ label, pathname, icon, onClose, onClick, active }) => {
    const ListButtonWrapper = pathname ? ListItemButtonLink : ListItemButton;

    const onClickHandler = () => {
        onClose?.();
        onClick && onClick?.();
    };

    return (
        <ColoredListItem button key={label} disablePadding active={active}>
            <ListButtonWrapper {...(pathname && { to: { pathname } })} onClick={onClickHandler}>
                <ColoredListItemIcon active={active}>{icon}</ColoredListItemIcon>
                <StyledListItemText primary={label} />
            </ListButtonWrapper>
        </ColoredListItem>
    );
};

SidebarListItem.propTypes = {
    pathname: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    icon: PropTypes.element.isRequired,
    onClose: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    active: PropTypes.bool,
};

export default SidebarListItem;
