import { useCallback } from 'react';

import * as Sentry from '@sentry/nextjs';

import useApi from '@components/api/useApi';

const usePlayListUtils = () => {
    const api = useApi();

    const fetchNextTrack = useCallback(async (mediaId, playListId) => {
        try {
            const nextUrl = playListId ? `/media/next/${mediaId}?playlistId=${playListId}` : `/media/next/${mediaId}`;
            const track = await api.fetcher(nextUrl);
            return track ? { ...track, playListId } : null;
        } catch (error) {
            Sentry.captureException(error);
            return null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { fetchNextTrack };
};

export default usePlayListUtils;
