import React from 'react';

import { getLinks } from 'cache';

import Link from '@ui/link/Link';
import List from '@ui/List';
import ListItem from '@ui/ListItem';

import useCms from '@components/Cms/useCms';

const StaticPageLinks = ({ onClose, target }) => {
    const cms = useCms();
    return (
        <List dense>
            <ListItem>
                <Link
                    underline="hover"
                    sx={{ color: 'text.secondary' }}
                    href={getLinks('podcasterApplication')}
                    target={target}
                    onClick={onClose}
                >
                    {cms[1060]}
                </Link>
            </ListItem>
            <ListItem>
                <Link
                    prefetch={false}
                    underline="hover"
                    sx={{ color: 'text.secondary' }}
                    href={getLinks('privacy-policy')}
                    target={target}
                    onClick={onClose}
                >
                    {cms[2006]}
                </Link>
            </ListItem>
            <ListItem>
                <Link
                    prefetch={false}
                    underline="hover"
                    sx={{ color: 'text.secondary' }}
                    href={getLinks('legal-statement')}
                    target={target}
                    onClick={onClose}
                >
                    {cms[2004]}
                </Link>
            </ListItem>
            <ListItem>
                <Link
                    prefetch={false}
                    underline="hover"
                    sx={{ color: 'text.secondary' }}
                    href={getLinks('impressum')}
                    target={target}
                    onClick={onClose}
                >
                    {cms[2005]}
                </Link>
            </ListItem>
        </List>
    );
};

export default StaticPageLinks;
