import React, { useMemo } from 'react';

import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import ExploreRoundedIcon from '@mui/icons-material/ExploreRounded';
import FavoriteIcon from '@mui/icons-material/FavoriteRounded';
import LanguageIcon from '@mui/icons-material/Language';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusicRounded';
import LockOpenIcon from '@mui/icons-material/LockOpenRounded';
import PersonIcon from '@mui/icons-material/PersonRounded';
import PodcastsIcon from '@mui/icons-material/PodcastsRounded';
import RadioIcon from '@mui/icons-material/RadioRounded';
import SlideshowRoundedIcon from '@mui/icons-material/SlideshowRounded';
import { getLinks } from 'cache';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useIsDesktop } from 'utils/hooks';

import Cms from '@components/Cms/Cms';
import useCms from '@components/Cms/useCms';
import useRegisterDialog from '@components/RegisterDialog/useRegisterDialog';
import useUser from '@components/User/useUser';

import SidebarList from './SidebarList';
import SidebarListItem from './SidebarListItem';

const UserSideBar = ({ onClose }) => {
    const isDesktop = useIsDesktop();
    const { user, hasPermissions, userPodcasters } = useUser();
    const cms = useCms();
    const router = useRouter();
    const location = router.asPath;
    const { openRegisterDialog } = useRegisterDialog();

    const isShowUserMenu = !isDesktop && !user;
    const hasMultiplePodcasters = userPodcasters.length > 1;

    const links = useMemo(
        () =>
            [
                isShowUserMenu && {
                    label: cms[4],
                    listItems: [
                        {
                            path: getLinks('register'),
                            label: cms[2],
                            icon: <LockOpenIcon />,
                        },
                        {
                            path: getLinks('login'),
                            label: cms[1],
                            icon: <PersonIcon />,
                        },
                    ],
                },
                {
                    listItems: [
                        {
                            path: getLinks('home'),
                            label: cms[8],
                            icon: <ExploreRoundedIcon />,
                        },
                        {
                            path: getLinks('podcasts'),
                            label: cms[10],
                            icon: <PodcastsIcon />,
                        },
                        {
                            path: getLinks('foreignPodcasts'),
                            label: cms[5000],
                            icon: <LanguageIcon />,
                        },
                        {
                            path: getLinks('radios'),
                            label: cms[11],
                            icon: <RadioIcon />,
                        },
                        {
                            path: getLinks('categories'),
                            label: cms[21],
                            icon: <CategoryRoundedIcon />,
                        },
                    ],
                },
                {
                    label: cms[13],
                    listItems: [
                        {
                            ...(user ? { path: getLinks('my-list') } : { onClick: openRegisterDialog }),
                            label: cms[14],
                            icon: <LibraryMusicIcon />,
                        },
                        {
                            ...(user ? { path: getLinks('favorites') } : { onClick: openRegisterDialog }),
                            label: cms[15],
                            icon: <FavoriteIcon />,
                        },
                        ...(user && hasPermissions(['podcaster']) && !hasPermissions('admin')
                            ? user.userPermissions
                                  .filter(({ permission }) => permission === 'podcaster')
                                  .map(({ entityId }, index) => ({
                                      path: getLinks('podcasterProfile', entityId),
                                      label: hasMultiplePodcasters ? <Cms cmsId={899} args={[index + 1]} /> : cms[1070],
                                      icon: <SlideshowRoundedIcon />,
                                  }))
                            : []),
                    ],
                },
            ]
                .filter(Boolean)
                .map(({ label, listItems }, blockIndex) => (
                    <SidebarList label={label} key={blockIndex}>
                        {listItems.map(({ path, label, icon, onClick }, linkIndex) => (
                            <SidebarListItem
                                key={linkIndex}
                                active={path === location}
                                pathname={path}
                                label={label}
                                icon={icon}
                                onClick={onClick}
                                onClose={onClose}
                            />
                        ))}
                    </SidebarList>
                )),
        [cms, onClose, hasMultiplePodcasters, isShowUserMenu, location, user, openRegisterDialog, hasPermissions]
    );

    return links;
};

UserSideBar.propTypes = {
    onClose: PropTypes.func,
};

export default UserSideBar;
