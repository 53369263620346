import React from 'react';

import { TableHead as MuiTableHead, TableCell } from '@mui/material';
import PropTypes from 'prop-types';

import Checkbox from '@ui/inputs/Checkbox';

import tablePropTypes from './Table.propTypes';
import TableRow from './TableRow';

const TableHead = ({ columns, orderable, editable, selectable, size, onSelectAll, allSelected }) => {
    const head = (columns || []).map(({ title, id, sx }) => (
        <TableCell sx={sx} key={id}>
            {title}
        </TableCell>
    ));

    return (
        <MuiTableHead position="relative">
            <TableRow>
                {selectable && (
                    <TableCell>
                        <Checkbox
                            size={size}
                            checked={allSelected}
                            onChange={({ target: { checked } }) => onSelectAll(checked)}
                        />
                    </TableCell>
                )}
                {orderable && <TableCell></TableCell>}
                {head}
                {editable && <TableCell></TableCell>}
            </TableRow>
        </MuiTableHead>
    );
};

TableHead.propTypes = {
    size: tablePropTypes.size,
    columns: tablePropTypes.columns,
    orderable: tablePropTypes.orderable,
    selectable: tablePropTypes.selectable,
    editable: tablePropTypes.orderable,
    onSelectAll: tablePropTypes.onSelectAll,
    allSelected: PropTypes.bool,
};

export default React.memo(TableHead);
