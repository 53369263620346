import React, { useEffect, useState } from 'react';

import { getCms } from '@cache/index';
import { useRouter } from 'next/router';

import CmsContext from './CmsContext';
import { getFromSessionStorage, saveToSessionStorage } from '../../utils/storage';

export const getShowCmsIds = param => {
    const session = getFromSessionStorage('SHOW_CMS_ID', false);
    if (param) {
        saveToSessionStorage('SHOW_CMS_ID', true);
    }

    return !!param || !!session;
};

const toCmsContext = showCmsIds =>
    getCms().reduce(
        (acc, { text, id }) => {
            return {
                ...acc,
                [id]: showCmsIds ? `${text} [${id}]` : text,
            };
        },
        { loading: false }
    );

const CmsContextProvider = ({ children }) => {
    /* eslint-disable camelcase */
    const { query } = useRouter();
    const { show_cms_id } = query || {};

    const [context, setContext] = useState(toCmsContext(show_cms_id));

    useEffect(() => {
        const showCmsIds = getShowCmsIds(show_cms_id);
        setContext(toCmsContext(showCmsIds));
    }, [show_cms_id]);

    return <CmsContext.Provider value={context}>{children}</CmsContext.Provider>;
};

export default CmsContextProvider;
