import React from 'react';

import { styled } from '@mui/system';
import PropTypes from 'prop-types';

import { Check, Warning } from '@mui/icons-material';

import Box from '@ui/Box';
import CircularProgress from '@ui/CircularProgress';
import Link from '@ui/link/Link';
import Tooltip from '@ui/Tooltip';
import Typography from '@ui/Typography';

import useCms from '@components/Cms/useCms';

const RowContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:hover': {
        backgroundColor: theme.palette.background.light,
    },
}));

const Name = styled(Typography, {
    shouldForwardProp: prop => prop !== 'inProgress' && prop !== 'isStarted',
})(({ theme, inProgress, isStarted }) => ({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '150px',
    overflow: 'hidden',
    fontWeight: inProgress ? 700 : 400,
    color: !isStarted ? theme.palette.text.secondary : theme.palette.text.primary,
    marginLeft: theme.spacing(1),
}));

const AudioUploadSnackbarRow = ({ name, progressPercent, viewLink, isStarted, isCompleted, error }) => {
    const cms = useCms();
    const inProgress = isStarted && !isCompleted;
    const success = !error && isCompleted;

    return (
        <Tooltip title={name}>
            <RowContainer>
                <Box display="flex" alignItems="center">
                    {success && <Check color="success" />}
                    {error && (
                        <Tooltip title={error}>
                            <Warning color="error" />
                        </Tooltip>
                    )}
                    {inProgress && (
                        <CircularProgress size={20} thickness={5} variant="determinate" value={progressPercent} />
                    )}
                    <Name inProgress={inProgress} isStarted={isStarted}>
                        {name}
                    </Name>
                </Box>
                {inProgress && (
                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                        {progressPercent}%
                    </Typography>
                )}
                {success && (
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Link href={viewLink}>{cms[150]}</Link>
                    </Typography>
                )}
                {!isStarted && (
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {cms[149]}
                    </Typography>
                )}
            </RowContainer>
        </Tooltip>
    );
};

AudioUploadSnackbarRow.propTypes = {
    name: PropTypes.string,
    progressPercent: PropTypes.number,
    isStarted: PropTypes.bool,
    isCompleted: PropTypes.bool,
    error: PropTypes.string,
    viewLink: PropTypes.string,
};

export default AudioUploadSnackbarRow;
