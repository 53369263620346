import React from 'react';

import { styled } from '@mui/system';

import useCms from '@components/Cms/useCms';

const Badge = styled('div')(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: 14,
    marginRight: theme.spacing(2),
}));

const LiveBadge = ({ type }) => {
    const cms = useCms();
    return <>{type === 'radio' && <Badge>{cms[2015]}</Badge>}</>;
};

export default LiveBadge;
