import React, { useState } from 'react';

import RegisterDialog from './RegisterDialog';
import RegisterDialogContext from './RegisterDialogContext';

const RegisterDialogContextProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const openRegisterDialog = () => setIsOpen(true);

    const closeRegisterDialog = () => setIsOpen(false);

    return (
        <RegisterDialogContext.Provider
            value={{
                isOpen,
                openRegisterDialog,
                closeRegisterDialog,
            }}
        >
            {children}
            <RegisterDialog isOpen={isOpen} onClose={closeRegisterDialog} />
        </RegisterDialogContext.Provider>
    );
};

export default RegisterDialogContextProvider;
