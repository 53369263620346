import React from 'react';

import { MenuItem as MuiMenuItem } from '@mui/material';

const MenuItem = props => {
    const { children, ...rest } = props;
    return <MuiMenuItem {...rest}>{children}</MuiMenuItem>;
};

export default MenuItem;
