import React, { createContext, useContext, useState } from 'react';

import LockedContentPopup from './LockedContentPopup';

const LockedContentContext = createContext({});

const LockedContentContextProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const openLockedPopup = () => {
        setIsOpen(true);
    };

    const closeLockedContentPopup = () => {
        setIsOpen(false);
    };

    return (
        <LockedContentContext.Provider
            value={{
                isOpen,
                openLockedPopup,
                closeLockedContentPopup,
            }}
        >
            {children}
            <LockedContentPopup isOpen={isOpen} onClose={closeLockedContentPopup} />
        </LockedContentContext.Provider>
    );
};

export const useLockedContentPopup = () => {
    const context = useContext(LockedContentContext);

    if (!context) {
        throw new Error('useLockedContentPopup must be used within LockedContentContextProvider');
    }

    return context;
};

export default LockedContentContextProvider;
