import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

// https://github.com/getsentry/sentry-javascript/issues/3040
const skipRegex = /undefined is not an object (evaluating 'window.webkit.messageHandlers')/i;

const shouldSkip = ({ originalException }) => {
    const message = originalException?.message || '';
    return message.match(skipRegex);
};

Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 0.25,
    denyUrls: [
        // cookie consent
        /quantcast\.mgr\.consensu\.org/i,
        // google pixel
        /pagead2\.googlesyndication\.com/i,
        // box page
        /podpad\.hu\/box\//i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    ignoreErrors: [
        'Failed to fetch',
        // Avast extension error
        '_avast_submit',
    ],
    beforeSend(event, hint) {
        if (shouldSkip(hint)) {
            return null;
        }

        return event;
    },
});
