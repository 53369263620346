import React, { useCallback, useContext, useMemo } from 'react';

import { isDevelopment } from '@utils/common';

const AnalyticsContext = React.createContext({});

const AnalyticsContextProvider = ({ children }) => {
    const logEvent = useCallback((eventName, params) => {
        if (isDevelopment()) {
            console.log('logEvent', eventName, params);
            return;
        }

        window?.dataLayer?.push({
            event: eventName,
            ...params,
        });
    }, []);

    const logMediaParams = useCallback(
        mediaData => ({
            title: mediaData?.title,
            ...(mediaData?.parentMedia?.title && { podcastTitle: mediaData?.parentMedia?.title }),
        }),
        []
    );

    const logPlayStarted = useCallback(
        mediaData => {
            logEvent('play_content', logMediaParams(mediaData));
        },
        [logEvent, logMediaParams]
    );

    const logPlay = useCallback(
        (mediaData, position) => {
            logEvent('playing_content', {
                ...logMediaParams(mediaData),
                position,
            });
        },
        [logEvent, logMediaParams]
    );

    const logAdFullyPlayed = useCallback(
        mediaData => {
            logEvent('ad_fully_played', logMediaParams(mediaData));
        },
        [logEvent, logMediaParams]
    );

    const logSeek = useCallback(
        (mediaData, seekFrom, seekTo) => {
            logEvent('seeked', {
                ...logMediaParams(mediaData),
                seekFrom,
                seekTo,
            });
        },
        [logEvent, logMediaParams]
    );

    const logPlayContent1min = useCallback(
        mediaData => {
            logEvent('play_content_1_min', logMediaParams(mediaData));
        },
        [logEvent, logMediaParams]
    );

    const logPlayContent5min = useCallback(
        mediaData => {
            logEvent('play_content_5_min', logMediaParams(mediaData));
        },
        [logEvent, logMediaParams]
    );

    const logLogin = useCallback(
        method => {
            if (method === 'azureAd') {
                return;
            }

            logEvent('login', {
                method: method || 'email',
            });
        },
        [logEvent]
    );

    const logSignUp = useCallback(
        method => {
            if (method === 'azureAd') {
                return;
            }

            logEvent('sign_up', {
                method: method || 'email',
            });
        },
        [logEvent]
    );

    const value = useMemo(
        () => ({
            logSignUp,
            logLogin,
            logPlayStarted,
            logAdFullyPlayed,
            logPlayContent1min,
            logPlayContent5min,
            logSeek,
            logPlay,
        }),
        [
            logAdFullyPlayed,
            logLogin,
            logPlayContent1min,
            logPlayContent5min,
            logPlayStarted,
            logSignUp,
            logSeek,
            logPlay,
        ]
    );

    return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = () => {
    const analytics = useContext(AnalyticsContext);

    if (!analytics) {
        throw new Error('useAnalytics must be used within a AnalyticsContextProvider');
    }

    return analytics;
};

export default AnalyticsContextProvider;
