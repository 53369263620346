import { useCallback, useState } from 'react';

export const useTableSelection = ({ keyName, defaultValue }) => {
    const [selectedKeys, setSelectedKeys] = useState(defaultValue || []);

    const onSelect = useCallback(
        (row, checked) => {
            setSelectedKeys(prev => (checked ? [...prev, row[keyName]] : prev.filter(key => key !== row[keyName])));
        },
        [keyName]
    );

    const onSelectAll = useCallback(
        rows => {
            setSelectedKeys(rows.map(row => row[keyName]));
        },
        [keyName]
    );

    return [selectedKeys, onSelect, onSelectAll];
};
