import React from 'react';

import * as Sentry from '@sentry/nextjs';
import { clearSession, getDeviceId, getSessionId } from '@utils/session';
import { useRouter } from 'next/router';
import { SWRConfig } from 'swr';
import { getToken } from 'utils/token';

import ApiContext from './ApiContext';

const userRoutes = ['/my'];

const ApiContextProvider = ({ children }) => {
    const router = useRouter();
    const uploadAsset = (file, policy, progressCallback, entityId) => {
        return new Promise((resolve, reject) => {
            const worker = new Worker(new URL('../../upload.worker.js', import.meta.url));

            worker.onmessage = event => {
                const data = event.data;
                const { error, currentPart, totalParts } = data;
                const isCompleted = error || currentPart === totalParts;
                if (progressCallback) {
                    progressCallback({ isCompleted, ...data });
                }

                if (isCompleted) {
                    worker.terminate();
                    if (error) {
                        reject(error);
                    } else {
                        resolve();
                    }
                }
            };

            worker.postMessage({ file, policy, entityId, token: getToken() });
        });
    };

    const combineApiPath = (path, { method, useCache } = {}) => {
        const isCache = useCache || ((!method || method === 'GET') && !getToken() && !userRoutes.includes(path));
        const baseUrl = isCache ? process.env.NEXT_PUBLIC_API_CDN : process.env.NEXT_PUBLIC_API_URL;
        return path?.startsWith('http') || path?.startsWith('https')
            ? path
            : baseUrl + (path?.startsWith('/') ? '' : '/') + path;
    };

    const setInit = (init, token) => {
        const request = init || {};
        return {
            ...request,
            headers: {
                'X-Device-Id': getDeviceId(),
                'X-Session-Id': getSessionId(),
                ...request.headers,
                ...(!!token && { Authorization: token }),
                'Content-Type': 'application/json',
            },
        };
    };

    const fetcher = (url, init, token) =>
        fetch(combineApiPath(url, init), setInit(init, token || getToken()))
            .then(response => {
                if (!response.ok) {
                    if (!token && !!response.headers.get('x-invalid-token')) {
                        clearSession();
                        Sentry.setUser(null);
                        router.reload(window.location.pathname);
                    }

                    return response.json().then(errorData => {
                        throw errorData;
                    });
                }

                return response.json();
            })
            .catch(error => {
                if (error) {
                    throw error;
                }

                throw new Error('Network error!');
            });

    const post = (url, data, token) => fetcher(url, { method: 'POST', body: JSON.stringify(data || {}) }, token);
    const get = url => fetcher(url, { method: 'GET' });

    const deleteRequest = (url, data) => fetcher(url, { method: 'DELETE', body: JSON.stringify(data || {}) });

    return (
        <ApiContext.Provider value={{ fetcher, post, get, deleteRequest, uploadAsset }}>
            <SWRConfig
                value={{
                    shouldRetryOnError: false,
                    revalidateOnFocus: false,
                    fetcher,
                }}
            >
                {children}
            </SWRConfig>
        </ApiContext.Provider>
    );
};

export default ApiContextProvider;
