import React from 'react';

import { CircularProgress, Button as MuiButton } from '@mui/material';
import { styled, useTheme } from '@mui/system';

const LoadingIndicator = styled(CircularProgress)(({ theme }) => ({
    marginRight: theme.spacing(1),
    marginLeft: -theme.spacing(1) - theme.typography.fontSize,
}));

const Button = props => {
    const { children, loading, disableElevation = true, disabled, ...rest } = props;
    const theme = useTheme();

    return (
        <MuiButton variant="contained" disabled={disabled || loading} disableElevation={disableElevation} {...rest}>
            {loading && <LoadingIndicator size={theme.typography.fontSize} color="inherit" />}
            {children}
        </MuiButton>
    );
};

export default Button;
