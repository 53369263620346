import React from 'react';

import { Menu as MuiMenu } from '@mui/material';

const Menu = props => {
    const { children, ...rest } = props;
    return <MuiMenu {...rest}>{children}</MuiMenu>;
};

export default Menu;
