import React from 'react';

import { ListItemText as MuiListItemText } from '@mui/material';

const ListItemText = props => {
    const { children, ...rest } = props;
    return <MuiListItemText {...rest}>{children}</MuiListItemText>;
};

export default ListItemText;
