import React from 'react';

import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { isMaintenance } from '@utils/common';

import AdBannerScript from '@components/AdBanner/AdBannerScript';
import Network from '@components/api/ApiContextProvider';
import createEmotionCache from '@components/app/cache/createEmotionCache';
import useGemius from '@components/app/hooks/useGemius';
import useIds from '@components/app/hooks/useIds';
import AudioUploadContextProvider from '@components/AudioUpload/AudioUploadContextProvider';
import CmsContextProvider from '@components/Cms/CmsContextProvider';
import EventSinkContextProvider from '@components/EventSink/EventSinkContextProvider';
import LockedContentContextProvider from '@components/LockedContent/LockedContentContextProvider';
import MatureContextProvider from '@components/MatureContext/MatureContextProvider';
import Page from '@components/page/Page';
import MaintenancePage from '@components/pages/MaintenancePage';
import PodcasterLevelDialogContextProvider from '@components/PodcasterLevelDialog/PodcasterLevelDialogContextProvider';
import AnalyticsContextProvider from '@components/Providers/AnalyticsContextProvider';
import RadiantPlayerContextProvider from '@components/RadiantPlayer/RadiantPlayerContextProvider';
import RegisterDialogContextProvider from '@components/RegisterDialog/RegisterDialogContextProvider';
import UserContextProvider from '@components/User/UserContextProvider';
import WrappedToastContainer from '@components/WrappedToastContainer/WrappedToastContainer';

import ThemeContextProvider from '@theme/ThemeContextProvider';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const App = ({ Component, pageProps, emotionCache = clientSideEmotionCache }) => {
    useIds();
    useGemius();

    if (isMaintenance()) {
        return (
            <ThemeContextProvider>
                <CssBaseline />
                <AdBannerScript />
                <MaintenancePage />
            </ThemeContextProvider>
        );
    }

    return (
        <CacheProvider value={emotionCache}>
            {!Component?.pageConfig?.embedPlayer && !Component?.pageConfig?.disabledAdBanner && <AdBannerScript />}
            <Network>
                <AnalyticsContextProvider>
                    <CmsContextProvider serverSideCms={pageProps?.serverSideCms}>
                        <UserContextProvider>
                            <EventSinkContextProvider>
                                <ThemeContextProvider>
                                    <CssBaseline />
                                    <WrappedToastContainer />
                                    <AudioUploadContextProvider>
                                        <RegisterDialogContextProvider>
                                            <PodcasterLevelDialogContextProvider>
                                                <LockedContentContextProvider>
                                                    <RadiantPlayerContextProvider
                                                        embedPlayer={Component?.pageConfig?.embedPlayer}
                                                        promoBoxPlayer={Component?.pageConfig?.promoBoxPlayer}
                                                    >
                                                        <MatureContextProvider>
                                                            <Page
                                                                {...(Component?.pageConfig || {})}
                                                                pageProps={pageProps}
                                                            >
                                                                <Component {...pageProps} />
                                                            </Page>
                                                        </MatureContextProvider>
                                                    </RadiantPlayerContextProvider>
                                                </LockedContentContextProvider>
                                            </PodcasterLevelDialogContextProvider>
                                        </RegisterDialogContextProvider>
                                    </AudioUploadContextProvider>
                                </ThemeContextProvider>
                            </EventSinkContextProvider>
                        </UserContextProvider>
                    </CmsContextProvider>
                </AnalyticsContextProvider>
            </Network>
        </CacheProvider>
    );
};

export default App;
