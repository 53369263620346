import React from 'react';

import { getLinks } from 'cache';
import Image from 'next/image';
import PropTypes from 'prop-types';

import AppBar from '@ui/AppBar';
import Box from '@ui/Box';
import IconButtonLink from '@ui/link/IconButtonLink';
import Toolbar from '@ui/Toolbar';
import Typography from '@ui/Typography';

import Ghosting from '@components/auth/Ghosting';
import useCms from '@components/Cms/useCms';

import huSiteLogo from './podpad_logo.svg';
import UserMenu from './Sidebar/UserMenu';

const SimpleLayout = ({ children }) => {
    const cms = useCms();

    return (
        <Box sx={{ display: 'flex' }}>
            <Ghosting />
            <AppBar position="fixed">
                <Toolbar>
                    <IconButtonLink color="inherit" edge="start" size="large" to={{ pathname: getLinks('home') }}>
                        <Image src={huSiteLogo} alt={cms[7]} />
                    </IconButtonLink>
                    <Typography variant="h6" noWrap component="div">
                        {cms[7]}
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <UserMenu hideProfileMenu />
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="main" sx={{ flexGrow: 1, pl: 3, pr: 3, pb: '100px' }}>
                <Toolbar sx={{ mb: 3 }} />
                {children}
            </Box>
        </Box>
    );
};

SimpleLayout.propTypes = {
    isAdminPage: PropTypes.bool,
};

export default SimpleLayout;
