import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import FormFromHook from './FormFromHook';

const Form = ({ children, forceRefresh, onSubmit, schema, defaultValues, ...rest }) => {
    const form = useForm({
        resolver: yupResolver(schema),
        defaultValues,
    });
    const { reset } = form;

    useEffect(() => {
        if (forceRefresh) {
            reset(defaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceRefresh]);

    return (
        <FormFromHook onSubmit={onSubmit} form={form} {...rest}>
            {children}
        </FormFromHook>
    );
};

Form.propTypes = {
    onSubmit: PropTypes.func,
    schema: PropTypes.object,
    defaultValues: PropTypes.object,
    forceRefresh: PropTypes.bool,
};

export default Form;
