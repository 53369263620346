import React, { useEffect, useState } from 'react';

import { styled } from '@mui/system';

import { Close, ExpandLess, ExpandMore } from '@mui/icons-material';

import Box from '@ui/Box';
import Collapse from '@ui/Collapse';
import IconButton from '@ui/IconButton';
import Paper from '@ui/Paper';
import Snackbar from '@ui/Snackbar';
import Tooltip from '@ui/Tooltip';
import Typography from '@ui/Typography';

import useAudioUpload from '@components/AudioUpload/useAudioUpload';
import useCms from '@components/Cms/useCms';

import AudioUploadSnackbarRow from './AudioUploadSnackbarRow';

const PaperStyled = styled(Paper)({
    overflow: 'hidden',
    width: '360px',
});

const Headline = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[900],
    color: 'white',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
}));

const AudioUploadSnackbar = () => {
    const { uploadQueueMeta, uploading, currentIndex, reset } = useAudioUpload();
    const queueLength = uploadQueueMeta.length;
    const [open, setOpen] = useState(false);
    const [minimized, setMinimized] = useState(false);
    const cms = useCms();

    const handleClose = () => {
        setOpen(false);
        reset();
    };

    useEffect(() => {
        if (queueLength > 0) {
            setOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadQueueMeta]);

    return (
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={open}>
            <PaperStyled>
                <Headline>
                    <Box display="flex" alignItems="center">
                        {!uploading && (
                            <Tooltip title={cms[153]}>
                                <IconButton onClick={handleClose} size="large">
                                    <Close />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Typography>
                            {cms[146]} ({Math.min(currentIndex + 1, queueLength)}/{queueLength})
                        </Typography>
                    </Box>
                    <Tooltip title={minimized ? cms[148] : cms[147]}>
                        <IconButton onClick={() => setMinimized(!minimized)} size="large">
                            {minimized ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </Tooltip>
                </Headline>
                <Collapse in={!minimized}>
                    {uploadQueueMeta.map(
                        ({ name, progressPercent, viewLink, isStarted, isCompleted, error }, index) => {
                            return (
                                <AudioUploadSnackbarRow
                                    key={index}
                                    name={name}
                                    progressPercent={progressPercent}
                                    isStarted={isStarted}
                                    isCompleted={isCompleted}
                                    viewLink={viewLink}
                                    error={error ? (error?.error ? error.error : cms[262]) : undefined}
                                />
                            );
                        }
                    )}
                </Collapse>
            </PaperStyled>
        </Snackbar>
    );
};

export default AudioUploadSnackbar;
