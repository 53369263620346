import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import CircularProgress from '@ui/CircularProgress';

import TextField from '../inputs/TextField';

const FormAutoComplete = ({
    name,
    id,
    label,
    placeholder,
    sx,
    defaultValue,
    loading,
    startAdornment,
    ...autoCompleteProps
}) => {
    const formContext = useFormContext();
    return (
        <Controller
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
                return (
                    <Autocomplete
                        disablePortal
                        id={id}
                        value={value}
                        onChange={(_event, newValue) => {
                            onChange(newValue);
                        }}
                        {...autoCompleteProps}
                        renderInput={params => (
                            <TextField
                                {...params}
                                error={!!error}
                                onBlur={onBlur}
                                inputRef={ref}
                                helperText={error?.message}
                                label={label}
                                placeholder={placeholder}
                                sx={sx}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <>
                                            {startAdornment}
                                            {params.InputProps.startAdornment}
                                        </>
                                    ),
                                    endAdornment: (
                                        <>
                                            {loading ? <CircularProgress size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                );
            }}
            name={name}
            control={formContext.control}
            defaultValue={defaultValue || null}
        />
    );
};

FormAutoComplete.defaultProps = {
    sx: { mb: 2 },
};

FormAutoComplete.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    startAdornment: PropTypes.element,
    sx: PropTypes.object,
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default FormAutoComplete;
