import React from 'react';

import { IconButton as MuiIconButton } from '@mui/material';

const IconButton = React.forwardRef((props, ref) => {
    const { children, ...rest } = props;
    return (
        <MuiIconButton ref={ref} {...rest}>
            {children}
        </MuiIconButton>
    );
});

export default IconButton;
