import React from 'react';

import { getLinks } from 'cache';
import PropTypes from 'prop-types';

import Box from '@ui/Box';
import ButtonLink from '@ui/link/ButtonLink';
import Typography from '@ui/Typography';

import useCms from '@components/Cms/useCms';

const AuthButtons = ({ onRegisterClick, onLoginClick, rich, text, sx = {} }) => {
    const cms = useCms();
    return (
        <Box sx={sx}>
            <ButtonLink
                variant={rich ? 'contained' : 'text'}
                to={{ pathname: getLinks('login') }}
                onClick={onLoginClick}
                fullWidth={false}
                sx={{ mr: !(rich || text) ? 2 : 0 }}
            >
                {cms[1]}
            </ButtonLink>
            {(rich || text) && (
                <Typography
                    variant="body1"
                    component="span"
                    sx={{
                        marginX: { xs: text ? 0 : 1, sm: 2 },
                        marginY: { xs: 1, sm: 0 },
                    }}
                >
                    {cms[269]}
                </Typography>
            )}
            <ButtonLink
                variant={text ? 'text' : 'contained'}
                to={{ pathname: getLinks('register') }}
                onClick={onRegisterClick}
                fullWidth={false}
            >
                {cms[2]}
            </ButtonLink>
        </Box>
    );
};

AuthButtons.propTypes = {
    rich: PropTypes.bool,
    text: PropTypes.bool,
    onRegisterClick: PropTypes.func,
    onLoginClick: PropTypes.func,
    sx: PropTypes.object,
};

export default AuthButtons;
