import React, { useMemo } from 'react';

import podPadLogo from '@images/podpad-facebook-cover.jpg';
import Head from 'next/head';
import { useRouter } from 'next/router';

import useCms from '@components/Cms/useCms';

import metaPropTypes from './metaPropTypes';

const Meta = ({ meta, schemaJson }) => {
    const { title, description, image, imageWidth, imageHeight, url, ogType, misc } = meta;
    const cms = useCms();
    const router = useRouter();

    const metaTitle = title || cms[502];
    const metaDescription = description || cms[501];
    const metaImage = image || podPadLogo.src;
    const metaImageWidth = imageWidth || '1200';
    const metaImageHeight = imageHeight || '1200';
    const metaUrl = url || `${process.env.NEXT_PUBLIC_SITE_URL}${router.asPath}`;
    const fbAppId = process.env.NEXT_PUBLIC_FB_APP_ID;

    const metaOgTypes = ogType || 'website';

    const miscMeta = useMemo(
        () =>
            (misc || []).map(({ propName, propValue, content }, index) => {
                return React.createElement('meta', { key: index, [propName]: propValue, content });
            }),
        [misc]
    );

    const defaultSchemaJson = {
        '@context': 'https://schema.org/',
        '@type': 'WebSite',
        image: metaImage,
        url: metaUrl,
        name: metaTitle,
        description: metaDescription,
    };

    const jsonText = JSON.stringify({ ...defaultSchemaJson, ...schemaJson });

    return (
        <Head>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
            <title>{metaTitle}</title>
            <meta name="title" content={metaTitle} />
            <meta property="og:title" content={metaTitle} />
            <meta itemProp="name" content={metaTitle} />
            <meta name="description" content={metaDescription} />
            <meta property="og:description" content={metaDescription} />
            <meta itemProp="description" content={metaDescription} />
            <meta property="og:image" content={metaImage} />
            <meta property="og:image:width" content={metaImageWidth} />
            <meta property="og:image:height" content={metaImageHeight} />
            <meta itemProp="image" content={metaImage} />
            <meta property="og:url" content={metaUrl} />
            <link rel="canonical" href={metaUrl} />
            <meta property="fb:app_id" content={fbAppId} />
            <meta property="og:type" content={metaOgTypes} />
            <meta property="og:site_name" content={cms[503]} />
            {miscMeta}
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: jsonText }} />
        </Head>
    );
};

Meta.propTypes = metaPropTypes;

export default Meta;
