import React from 'react';

import { Box as MuiBox } from '@mui/material';

const Box = React.forwardRef((props, ref) => {
    const { children, ...rest } = props;
    return (
        <MuiBox ref={ref} {...rest}>
            {children}
        </MuiBox>
    );
});

export default Box;
