import { getLinks } from '@cache/index';

import { getUrlParam } from './browser';
import {
    clearSessionStorage,
    getFromLocalStorage,
    getFromSessionStorage,
    removeFromLocalStorage,
    removeFromSessionStorage,
    saveToLocalStorage,
    saveToSessionStorage,
} from './storage';

const tokenKey = 'TOKEN';
const ghostTokenKey = 'GHOST_TOKEN';

export const getToken = () => {
    const ghostToken = getFromSessionStorage(ghostTokenKey, null);
    const token = getFromLocalStorage(tokenKey, null);
    return ghostToken || token;
};

export const clearToken = () => removeFromLocalStorage(tokenKey);

export const setToken = token => saveToLocalStorage(tokenKey, token);

export const clearGhostToken = () => removeFromSessionStorage(ghostTokenKey);

export const setGhostToken = token => saveToSessionStorage(ghostTokenKey, token);

export const startGhosting = token => {
    setGhostToken(token);
    window.location = '/';
};

export const isGhosting = () => {
    const ghostToken = getFromSessionStorage(ghostTokenKey, null);
    return !!ghostToken;
};

export const getJwtPayload = token => {
    if (!token) {
        return null;
    }

    try {
        const base64Url = token.split('.')[1];
        const base64 = decodeURIComponent(
            atob(base64Url)
                .split('')
                .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
                .join(''),
        );

        return JSON.parse(base64);
    } catch {
        return null;
    }
};

export const unGhost = () => {
    clearGhostToken();
    window.location.reload();
};

export const checkGhost = () => {
    const token = getUrlParam('token');
    if (token) {
        setGhostToken(token);
        window.location.href = '/';
    }
};

const redirectKey = 'REDIRECT';

export const redirectToLogin = router => {
    const redirect = `${window.location.pathname}${window.location.search}`;
    saveToSessionStorage(redirectKey, redirect);
    router.replace(getLinks('login'));
};

export const getRedirectAfterLogin = (defaultRedirect = '/') => {
    const redirect = getFromSessionStorage(redirectKey, defaultRedirect);
    clearSessionStorage(redirectKey);
    return redirect;
};
