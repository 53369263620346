export const getId = prefix => {
    return `${prefix || ''}${Math.ceil(Math.random() * 1000000)}`;
};

export const isMaintenance = () => {
    return process.env.NEXT_PUBLIC_MAINTENANCE === 'true';
};

export const isDevelopment = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const createPropertyArray = propertiesObject =>
    Object.keys(propertiesObject).map(property => ({
        key: property,
        value: propertiesObject[property],
    }));
