import React, { forwardRef } from 'react';

import { TextField as MuiTextField } from '@mui/material';
import PropTypes from 'prop-types';

const TextField = forwardRef((props, ref) => <MuiTextField fullWidth inputRef={ref} {...props} />);

TextField.propTypes = {
    label: PropTypes.string,
};

export default TextField;
