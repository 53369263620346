/* eslint-disable max-lines */
import React, { useMemo } from 'react';

import { Check, DeleteSweep, PendingRounded, PolicyRounded, VisibilityOff, Warning } from '@mui/icons-material';

import useCms from '@components/Cms/useCms';

export const mediaTypes = {
    category: 'category',
    episode: 'episode',
    host: 'host',
    podcast: 'podcast',
    radio: 'radio',
};

export const mediaTypesCmsIds = {
    [mediaTypes.host]: 3029,
    [mediaTypes.podcast]: 3030,
};

export const mediaColumnNumber = {
    xs: {
        small: 2,
        normal: 2,
        large: 2,
        xLarge: 1,
    },
    sm: {
        small: 4,
        normal: 3,
        large: 2,
        xLarge: 1,
    },
    md: {
        small: 5,
        normal: 3,
        large: 2,
        xLarge: 2,
    },
    lg: {
        small: 8,
        normal: 5,
        large: 4,
        xLarge: 3,
    },
    xl: {
        small: 8,
        normal: 5,
        large: 4,
        xLarge: 3,
    },
};

export const statPermissions = ['stats-reader', 'owner'];

export const mediaStates = {
    unpublished: 'unpublished',
    processing: 'processing',
    inReview: 'inReview',
    published: 'published',
    deleting: 'deleting',
    active: 'active',
    disabled: 'disabled',
    error: 'error',
};

export const mediaStateMap = {
    [mediaStates.unpublished]: {
        color: 'secondary',
        icon: <VisibilityOff />,
        cmsId: 4096,
        noteCmsId: 4106,
    },
    [mediaStates.processing]: {
        color: 'secondary',
        icon: <PendingRounded />,
        cmsId: 4110,
        noteCmsId: 4111,
    },
    [mediaStates.inReview]: {
        color: 'secondary',
        icon: <PolicyRounded />,
        cmsId: 4105,
        noteCmsId: 4109,
    },
    [mediaStates.published]: {
        color: 'success',
        icon: <Check />,
        cmsId: 4097,
        noteCmsId: 4107,
    },
    [mediaStates.deleting]: {
        color: 'warning',
        icon: <DeleteSweep />,
        cmsId: 202,
    },
    [mediaStates.active]: {
        color: 'success',
        icon: <Check />,
        cmsId: 4100,
    },
    [mediaStates.disabled]: {
        color: 'secondary',
        icon: <VisibilityOff />,
        cmsId: 4101,
    },
    [mediaStates.error]: {
        color: 'warning',
        icon: <Warning />,
        cmsId: 4098,
        noteCmsId: 4108,
    },
};

export const podcasterTypes = {
    person: 'person',
    company: 'company',
};

export const podcasterTypesMap = {
    [podcasterTypes.person]: {
        tabId: 0,
        cmsId: 3095,
    },
    [podcasterTypes.company]: {
        tabId: 1,
        cmsId: 3096,
    },
};

export const usePodcasterTypesOptions = () => {
    const cms = useCms();

    return useMemo(
        () =>
            Object.keys(podcasterTypesMap).map(key => ({
                value: key,
                name: cms[podcasterTypesMap[key].cmsId],
            })),
        [cms]
    );
};

export const useSeasonOptions = () => {
    const cms = useCms();

    return useMemo(
        () => Array.from({ length: 10 }).map((_, index) => ({ value: index + 1, name: `${index + 1}. ${cms[4036]}` })),
        [cms]
    );
};

export const isContentLocked = mediaData => mediaData?.isContentLockedForUser || false;
