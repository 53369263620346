import React from 'react';

import { Typography as MuiTypography } from '@mui/material';

const Typography = props => {
    const { children, ...rest } = props;
    return <MuiTypography {...rest}>{children}</MuiTypography>;
};

export default Typography;
