export const convertDobToTimestamp = ({ year, month, day }) => {
    if (!year || !month || !day) {
        return null;
    }

    const utcDate = new Date(Date.UTC(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10)));
    return utcDate.getTime() / 1000.0;
};

export const convertTimeStampToDob = dob => {
    if (isNaN(dob)) {
        return {};
    }

    const date = new Date(dob * 1000);

    return {
        year: date.getUTCFullYear(),
        month: date.getUTCMonth() + 1,
        day: date.getUTCDate(),
    };
};

export const convertTimeStampToDate = timestamp => {
    const date = new Date(timestamp * 1000);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString('hu-HU', options);
};

export const dateAddToday = day => {
    const now = new Date();
    const date = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    date.setDate(date.getUTCDate() + day);
    return date.getTime() / 1000.0;
};

export const getTodayDateString = () => {
    var now = new Date();
    return now.toISOString().slice(0, 10).replace(/-/g, '');
};

const s = '2021-01-01 00:00';
const bits = s.split(/\D/);

export const getMonthOptions = () => {
    const montOptionsDateSeed = new Date(Date.UTC(bits[0], --bits[1], bits[2], bits[3], bits[4]));
    return Array.from({ length: 12 })
        .map((_, index) => {
            const name = montOptionsDateSeed.toLocaleDateString(undefined, { month: 'long' });
            montOptionsDateSeed.setUTCMonth(montOptionsDateSeed.getUTCMonth() + 1);
            return {
                name,
                value: index,
            };
        })
        .sort((a, b) => a.value - b.value);
};

export const oneDayS = 86400;

export const getMonthLengthInDays = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
};

export const getUTCNow = () => {
    const now = new Date();
    const date = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    return date;
};

export const formatDuration = (value, hasLeadingZero) => {
    const minute = Math.floor(value / 60);
    const secondLeft = (value - minute * 60).toFixed(0);
    return `${minute < 10 && hasLeadingZero ? `0${minute}` : minute}:${
        secondLeft < 10 ? `0${secondLeft}` : secondLeft
    }`;
};

export const createTimeStamp = () => {
    return new Date().getTime();
};
