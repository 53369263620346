import React from 'react';

import { Backdrop, CircularProgress, LinearProgress } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';

const BackdropStyled = styled(Backdrop)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'transparent',
}));

const LinearProgressStyled = styled(LinearProgress)({
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
});

const PageLoader = ({ style }) => (
    <BackdropStyled open={true}>
        {style === 'linear' ? <LinearProgressStyled color="primary" /> : <CircularProgress color="primary" />}
    </BackdropStyled>
);

PageLoader.defaultProps = {
    style: 'linear',
};

PageLoader.propTypes = {
    style: PropTypes.oneOf(['linear', 'circular']),
};

export default PageLoader;
