import React from 'react';

import ListItemIcon from '@mui/material/ListItemIcon';
import { styled } from '@mui/system';

import { RadioButtonCheckedRounded, RadioButtonUncheckedRounded, SpeedRounded } from '@mui/icons-material';

import Box from '@ui/Box';
import IconButton from '@ui/IconButton';
import Menu from '@ui/Menu';
import MenuItem from '@ui/MenuItem';
import Tooltip from '@ui/Tooltip';

import useCms from '@components/Cms/useCms';

import useRadiantPlayerContext from '../useRadiantPlayer';

const Container = styled(Box)({
    flexGrow: 0,
    flexShrink: 0,
    display: 'none',
    '@media screen and (min-width: 480px)': {
        display: 'block',
    },
});

const rates = [0.75, 1, 1.25, 1.5];

const PlaybackRateBtn = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const cms = useCms();
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { playbackRate, setPlaybackRate } = useRadiantPlayerContext();

    const handleSetPlaybackRate = rate => {
        setPlaybackRate(rate);
        handleClose();
    };

    return (
        <Container>
            <Tooltip title={cms[2021]}>
                <IconButton
                    aria-controls="playback-rate-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    color="secondary"
                >
                    <SpeedRounded />
                </IconButton>
            </Tooltip>
            <Menu
                dense
                sx={{ zIndex: 6001 }}
                id="playback-rate-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'playback-rate-menu',
                }}
            >
                {rates.map(rate => (
                    <MenuItem key={rate} onClick={() => handleSetPlaybackRate(rate)}>
                        {rate === playbackRate ? (
                            <>
                                <ListItemIcon>
                                    <RadioButtonCheckedRounded color="secondary" sx={{ fontSize: 16 }} />
                                </ListItemIcon>
                                {rate}x
                            </>
                        ) : (
                            <>
                                <ListItemIcon>
                                    <RadioButtonUncheckedRounded color="secondary" sx={{ fontSize: 16 }} />
                                </ListItemIcon>
                                {rate}x
                            </>
                        )}
                    </MenuItem>
                ))}
            </Menu>
        </Container>
    );
};

export default PlaybackRateBtn;
