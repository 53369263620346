import React, { useState } from 'react';

import PodcasterLevelDialog from './PodcasterLevelDialog';
import PodcasterLevelDialogContext from './PodcasterLevelDialogContext';

const PodcasterLevelDialogContextProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const openPodcasterLevelDialog = () => setIsOpen(true);

    const closePodcasterLevelDialog = () => setIsOpen(false);

    return (
        <PodcasterLevelDialogContext.Provider
            value={{
                isOpen,
                openPodcasterLevelDialog,
                closePodcasterLevelDialog,
            }}
        >
            {children}
            <PodcasterLevelDialog isOpen={isOpen} onClose={closePodcasterLevelDialog} />
        </PodcasterLevelDialogContext.Provider>
    );
};

export default PodcasterLevelDialogContextProvider;
