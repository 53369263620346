import { useCallback, useState } from 'react';

import { baseSearchBody, baseSuggestBody, searchPath, suggestPath } from '@utils/search';
import { getSessionId } from '@utils/session';

import useApi from '@components/api/useApi';
import useUser from '@components/User/useUser';

const modeMap = {
    search: {
        endpoint: searchPath,
        baseParams: baseSearchBody,
        mapper: response => {
            const resultFacets = response?.['@search.facets']?.Type || [];
            const resultArray = (response?.value || []).map(
                ({ Id: id, Hosts, Image: image, Slug: slug, Title: title, Type: type }) => ({
                    id,
                    hosts: Hosts.map(({ Title: title }) => ({ title })),
                    image,
                    slug,
                    title,
                    type,
                })
            );

            return { resultFacets, resultArray };
        },
    },
    suggest: {
        endpoint: suggestPath,
        baseParams: baseSuggestBody,
        mapper: response => response?.value?.map?.(({ Title }) => Title) || [],
    },
};

const useSearch = () => {
    const { isMature } = useUser();
    const api = useApi();
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSearch = useCallback(
        async (value, mode) => {
            const { endpoint, baseParams, mapper } = modeMap[mode];

            if (!value) {
                setResults([]);
                return;
            }

            setLoading(true);

            try {
                const response = await api.fetcher(endpoint, {
                    method: 'POST',
                    body: JSON.stringify({
                        ...(mode === 'suggest' ? {} : { sessionId: getSessionId() }),
                        ...baseParams,
                        ...(isMature === null || isMature === true ? {} : { filter: 'IsMatureContent eq false' }),
                        search: value,
                    }),
                    headers: {
                        'api-key': process.env.NEXT_PUBLIC_SEARCH_KEY,
                    },
                });

                setResults(mapper(response));
            } catch (err) {
                setResults([]);
            }

            setLoading(false);
        },
        [isMature, api]
    );

    const resetSearch = useCallback(() => {
        setResults([]);
    }, []);

    return [results, loading, handleSearch, resetSearch];
};

export default useSearch;
