import React, { useEffect, useState } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { useRouter } from 'next/router';

import useUser from '@components/User/useUser';

import ThemeContext from './ThemeContext';
import themes from './themes';

const themePalette = {
    dark: 'dark',
    light: 'light',
};

const ThemeContextProvider = ({ children }) => {
    const { user } = useUser();
    const [themeMode, setThemeMode] = useState(themePalette.dark);
    const router = useRouter();
    const { theme: themeQuery } = router.query;

    useEffect(() => {
        if ([themePalette.dark, themePalette.light].includes(themeQuery)) {
            setThemeMode(themeQuery);
            return;
        }

        const userTheme = user?.settings?.theme;
        if (!userTheme || userTheme === themeMode) {
            return;
        }

        setThemeMode(userTheme);
    }, [user, themeMode, themeQuery]);

    return (
        <ThemeContext.Provider value={{ themeMode, setThemeMode, themePalette }}>
            <ThemeProvider theme={themes[themeMode]}>{children}</ThemeProvider>
        </ThemeContext.Provider>
    );
};

export default ThemeContextProvider;
